
import ClickOutside from 'vue-click-outside'

export default {
  data() {
    return {
      localOpen: false
    }
  },
  props: ["open"],
  directives: {
    ClickOutside
  },
  methods: {
    show() {
      this.localOpen = true;
      this.$emit('update:open', this.localOpen);
    },
    toggle() {
      this.localOpen = !this.localOpen;
      this.$emit('update:open', this.localOpen);
    },
    hide() {
      this.localOpen = false;
      this.$emit('update:open', this.localOpen);
    }
  },
  watch: {
    open(newVal, oldVal) {
      this.localOpen = newVal
    }
  },
}
