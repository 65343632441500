import Vue from 'vue';

export default (d, inject) => {
    inject('utils', {
        copy: (textToCopy, confirmMessage) => {
            const tmpTextField = document.createElement("textarea")
            tmpTextField.textContent = textToCopy;
            tmpTextField.setAttribute("style", "position:absolute; right:200%;")
            document.body.appendChild(tmpTextField)
            tmpTextField.select()
            tmpTextField.setSelectionRange(0, 99999) /*For mobile devices*/
            document.execCommand("copy")
            tmpTextField.remove();

            Vue.prototype.$globalEvent.$emit('toast', {
                message: confirmMessage ?? Vue.prototype.$t('global.Text copied')
            })
        },
        baseDomain: () => {
            if (process.client) {
                return window.location.protocol + '//' + window.location.host;
            } else {
                return 'https://' + d.req.headers.host;
            }
        }
    })
}

