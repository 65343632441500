import axios from '../plugins/axios';
import Vue from 'vue';

import faq from './endpoints/faq';
import claims from './endpoints/claims';
import claimContracts from './endpoints/claimContracts';
import claimTypes from './endpoints/claimTypes';
import contents from './endpoints/contents';
import flightAirports from './endpoints/flightAirports';
import flightAircompany from './endpoints/flightAircompany';
import paymentsHelper from './endpoints/paymentsHelper';
import blogPosts from './endpoints/blogPosts';
import supportTicket from './endpoints/supportTicket';
import claimInformation from './endpoints/claimInformation';
import countries from './endpoints/countries';
import userCountry from './endpoints/userCountry';
import user from './endpoints/user';
import reviews from './endpoints/reviews';
import affiliate from './endpoints/affiliate';
import travelAgency from "./endpoints/travelAgency";
import shortLink from "./endpoints/shortLink";
import payments from "./endpoints/payments";

export const API = {
    faq: faq(axios),
    claims: claims(axios),
    claimContracts: claimContracts(axios),
    claimTypes: claimTypes(axios),
    contents: contents(axios),
    flightAirports: flightAirports(axios),
    flightAircompany: flightAircompany(axios),
    paymentsHelper: paymentsHelper(axios),
    blogPosts: blogPosts(axios),
    supportTicket: supportTicket(axios),
    claimInformation: claimInformation(axios),
    countries: countries(axios),
    userCountry: userCountry(axios),
    user: user(axios),
    reviews: reviews(axios),
    affiliate: affiliate(axios),
    shortLink: shortLink(axios),
    travelAgency: travelAgency(axios),
    payments: payments(axios),
};
