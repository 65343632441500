

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"en","market":"gb","file":"en-gb.js","domain":"www.troubleflight.com","date":"MM-DD-YYYY","name":"United Kingdom / English","country":"GB"},{"code":"ro","market":"ro","file":"ro-ro.js","domain":"www.troubleflight.ro","date":"DD.MM.YYYY","name":"Română / România","country":"RO"},{"code":"it","market":"it","file":"it-it.js","domain":"www.troubleflight.it","date":"DD/MM/YYYY","name":"Italy / Italian","country":"IT"},{"code":"de","market":"de","file":"de-de.js","domain":"www.troubleflight.de","date":"DD.MM.YYYY","name":"Deutsch / Deutschland","country":"DE"},{"code":"es","market":"es","file":"es-es.js","domain":"www.troubleflight.es","date":"DD/MM/YYYY","name":"Español / España","country":"ES"},{"code":"pt","market":"pt","file":"pt-pt.js","domain":"www.troubleflight.pt","date":"DD/MM/YYYY","name":"Português / Portugal","country":"PT"},{"code":"hu","market":"hu","file":"hu-hu.js","domain":"www.troubleflight.hu","date":"DD/MM/YYYY","name":"Magyar / Magyarország","country":"HU"},{"code":"pl","market":"pl","file":"pl-pl.js","domain":"www.troubleflight.pl","date":"DD/MM/YYYY","name":"Polski / Polska","country":"PL"},{"code":"sq","market":"al","file":"sq-al.js","domain":"www.troubleflight.al","date":"DD/MM/YYYY","name":"Shqip / Shqipëria","country":"AL"},{"code":"bg","market":"bg","file":"bg-bg.js","domain":"www.troubleflight.bg","date":"DD/MM/YYYY","name":"Български / България","country":"BG"},{"code":"cs","market":"cz","file":"cs-cz.js","domain":"www.troubleflight.cz","date":"DD.MM.YYYY","name":"Čeština / Česká republika","country":"CZ"},{"code":"ru","market":"md","file":"ru-ru.js","domain":"www.troubleflight.ru","date":"DD/MM/YYYY","name":"Русский язы́к / Росси́я","country":"RU"}],
  defaultLocale: "",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: {"skipNuxtState":true},
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  seo: false,
  normalizedLocales: [{"code":"en","market":"gb","file":"en-gb.js","domain":"www.troubleflight.com","date":"MM-DD-YYYY","name":"United Kingdom / English","country":"GB"},{"code":"ro","market":"ro","file":"ro-ro.js","domain":"www.troubleflight.ro","date":"DD.MM.YYYY","name":"Română / România","country":"RO"},{"code":"it","market":"it","file":"it-it.js","domain":"www.troubleflight.it","date":"DD/MM/YYYY","name":"Italy / Italian","country":"IT"},{"code":"de","market":"de","file":"de-de.js","domain":"www.troubleflight.de","date":"DD.MM.YYYY","name":"Deutsch / Deutschland","country":"DE"},{"code":"es","market":"es","file":"es-es.js","domain":"www.troubleflight.es","date":"DD/MM/YYYY","name":"Español / España","country":"ES"},{"code":"pt","market":"pt","file":"pt-pt.js","domain":"www.troubleflight.pt","date":"DD/MM/YYYY","name":"Português / Portugal","country":"PT"},{"code":"hu","market":"hu","file":"hu-hu.js","domain":"www.troubleflight.hu","date":"DD/MM/YYYY","name":"Magyar / Magyarország","country":"HU"},{"code":"pl","market":"pl","file":"pl-pl.js","domain":"www.troubleflight.pl","date":"DD/MM/YYYY","name":"Polski / Polska","country":"PL"},{"code":"sq","market":"al","file":"sq-al.js","domain":"www.troubleflight.al","date":"DD/MM/YYYY","name":"Shqip / Shqipëria","country":"AL"},{"code":"bg","market":"bg","file":"bg-bg.js","domain":"www.troubleflight.bg","date":"DD/MM/YYYY","name":"Български / България","country":"BG"},{"code":"cs","market":"cz","file":"cs-cz.js","domain":"www.troubleflight.cz","date":"DD.MM.YYYY","name":"Čeština / Česká republika","country":"CZ"},{"code":"ru","market":"md","file":"ru-ru.js","domain":"www.troubleflight.ru","date":"DD/MM/YYYY","name":"Русский язы́к / Росси́я","country":"RU"}],
  localeCodes: ["en","ro","it","de","es","pt","hu","pl","sq","bg","cs","ru"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "-",
  5: "g",
  6: "b",
  7: ".",
  8: "j",
  9: "s",
  10: "\"",
  11: ":",
  12: "\"",
  13: ".",
  14: ".",
  15: "/",
  16: "l",
  17: "a",
  18: "n",
  19: "g",
  20: "/",
  21: "e",
  22: "n",
  23: "-",
  24: "g",
  25: "b",
  26: ".",
  27: "j",
  28: "s",
  29: "\"",
  30: ",",
  31: "\"",
  32: "r",
  33: "o",
  34: "-",
  35: "r",
  36: "o",
  37: ".",
  38: "j",
  39: "s",
  40: "\"",
  41: ":",
  42: "\"",
  43: ".",
  44: ".",
  45: "/",
  46: "l",
  47: "a",
  48: "n",
  49: "g",
  50: "/",
  51: "r",
  52: "o",
  53: "-",
  54: "r",
  55: "o",
  56: ".",
  57: "j",
  58: "s",
  59: "\"",
  60: ",",
  61: "\"",
  62: "i",
  63: "t",
  64: "-",
  65: "i",
  66: "t",
  67: ".",
  68: "j",
  69: "s",
  70: "\"",
  71: ":",
  72: "\"",
  73: ".",
  74: ".",
  75: "/",
  76: "l",
  77: "a",
  78: "n",
  79: "g",
  80: "/",
  81: "i",
  82: "t",
  83: "-",
  84: "i",
  85: "t",
  86: ".",
  87: "j",
  88: "s",
  89: "\"",
  90: ",",
  91: "\"",
  92: "d",
  93: "e",
  94: "-",
  95: "d",
  96: "e",
  97: ".",
  98: "j",
  99: "s",
  100: "\"",
  101: ":",
  102: "\"",
  103: ".",
  104: ".",
  105: "/",
  106: "l",
  107: "a",
  108: "n",
  109: "g",
  110: "/",
  111: "d",
  112: "e",
  113: "-",
  114: "d",
  115: "e",
  116: ".",
  117: "j",
  118: "s",
  119: "\"",
  120: ",",
  121: "\"",
  122: "e",
  123: "s",
  124: "-",
  125: "e",
  126: "s",
  127: ".",
  128: "j",
  129: "s",
  130: "\"",
  131: ":",
  132: "\"",
  133: ".",
  134: ".",
  135: "/",
  136: "l",
  137: "a",
  138: "n",
  139: "g",
  140: "/",
  141: "e",
  142: "s",
  143: "-",
  144: "e",
  145: "s",
  146: ".",
  147: "j",
  148: "s",
  149: "\"",
  150: ",",
  151: "\"",
  152: "p",
  153: "t",
  154: "-",
  155: "p",
  156: "t",
  157: ".",
  158: "j",
  159: "s",
  160: "\"",
  161: ":",
  162: "\"",
  163: ".",
  164: ".",
  165: "/",
  166: "l",
  167: "a",
  168: "n",
  169: "g",
  170: "/",
  171: "p",
  172: "t",
  173: "-",
  174: "p",
  175: "t",
  176: ".",
  177: "j",
  178: "s",
  179: "\"",
  180: ",",
  181: "\"",
  182: "h",
  183: "u",
  184: "-",
  185: "h",
  186: "u",
  187: ".",
  188: "j",
  189: "s",
  190: "\"",
  191: ":",
  192: "\"",
  193: ".",
  194: ".",
  195: "/",
  196: "l",
  197: "a",
  198: "n",
  199: "g",
  200: "/",
  201: "h",
  202: "u",
  203: "-",
  204: "h",
  205: "u",
  206: ".",
  207: "j",
  208: "s",
  209: "\"",
  210: ",",
  211: "\"",
  212: "p",
  213: "l",
  214: "-",
  215: "p",
  216: "l",
  217: ".",
  218: "j",
  219: "s",
  220: "\"",
  221: ":",
  222: "\"",
  223: ".",
  224: ".",
  225: "/",
  226: "l",
  227: "a",
  228: "n",
  229: "g",
  230: "/",
  231: "p",
  232: "l",
  233: "-",
  234: "p",
  235: "l",
  236: ".",
  237: "j",
  238: "s",
  239: "\"",
  240: ",",
  241: "\"",
  242: "s",
  243: "q",
  244: "-",
  245: "a",
  246: "l",
  247: ".",
  248: "j",
  249: "s",
  250: "\"",
  251: ":",
  252: "\"",
  253: ".",
  254: ".",
  255: "/",
  256: "l",
  257: "a",
  258: "n",
  259: "g",
  260: "/",
  261: "s",
  262: "q",
  263: "-",
  264: "a",
  265: "l",
  266: ".",
  267: "j",
  268: "s",
  269: "\"",
  270: ",",
  271: "\"",
  272: "b",
  273: "g",
  274: "-",
  275: "b",
  276: "g",
  277: ".",
  278: "j",
  279: "s",
  280: "\"",
  281: ":",
  282: "\"",
  283: ".",
  284: ".",
  285: "/",
  286: "l",
  287: "a",
  288: "n",
  289: "g",
  290: "/",
  291: "b",
  292: "g",
  293: "-",
  294: "b",
  295: "g",
  296: ".",
  297: "j",
  298: "s",
  299: "\"",
  300: ",",
  301: "\"",
  302: "c",
  303: "s",
  304: "-",
  305: "c",
  306: "z",
  307: ".",
  308: "j",
  309: "s",
  310: "\"",
  311: ":",
  312: "\"",
  313: ".",
  314: ".",
  315: "/",
  316: "l",
  317: "a",
  318: "n",
  319: "g",
  320: "/",
  321: "c",
  322: "s",
  323: "-",
  324: "c",
  325: "z",
  326: ".",
  327: "j",
  328: "s",
  329: "\"",
  330: ",",
  331: "\"",
  332: "r",
  333: "u",
  334: "-",
  335: "r",
  336: "u",
  337: ".",
  338: "j",
  339: "s",
  340: "\"",
  341: ":",
  342: "\"",
  343: ".",
  344: ".",
  345: "/",
  346: "l",
  347: "a",
  348: "n",
  349: "g",
  350: "/",
  351: "r",
  352: "u",
  353: "-",
  354: "r",
  355: "u",
  356: ".",
  357: "j",
  358: "s",
  359: "\"",
  360: "}",
}

export const localeMessages = {
  'en-gb.js': () => import('../../lang/en-gb.js' /* webpackChunkName: "lang-en-gb.js" */),
  'ro-ro.js': () => import('../../lang/ro-ro.js' /* webpackChunkName: "lang-ro-ro.js" */),
  'it-it.js': () => import('../../lang/it-it.js' /* webpackChunkName: "lang-it-it.js" */),
  'de-de.js': () => import('../../lang/de-de.js' /* webpackChunkName: "lang-de-de.js" */),
  'es-es.js': () => import('../../lang/es-es.js' /* webpackChunkName: "lang-es-es.js" */),
  'pt-pt.js': () => import('../../lang/pt-pt.js' /* webpackChunkName: "lang-pt-pt.js" */),
  'hu-hu.js': () => import('../../lang/hu-hu.js' /* webpackChunkName: "lang-hu-hu.js" */),
  'pl-pl.js': () => import('../../lang/pl-pl.js' /* webpackChunkName: "lang-pl-pl.js" */),
  'sq-al.js': () => import('../../lang/sq-al.js' /* webpackChunkName: "lang-sq-al.js" */),
  'bg-bg.js': () => import('../../lang/bg-bg.js' /* webpackChunkName: "lang-bg-bg.js" */),
  'cs-cz.js': () => import('../../lang/cs-cz.js' /* webpackChunkName: "lang-cs-cz.js" */),
  'ru-ru.js': () => import('../../lang/ru-ru.js' /* webpackChunkName: "lang-ru-ru.js" */),
}
