import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7b17bea0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _609e09c7 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _3982abea = () => interopDefault(import('../pages/currency-rate.vue' /* webpackChunkName: "pages/currency-rate" */))
const _05732d6e = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _99f0dc9a = () => interopDefault(import('../pages/corporate.vue' /* webpackChunkName: "pages/corporate" */))
const _71a1cf65 = () => interopDefault(import('../pages/flight-compensation.vue' /* webpackChunkName: "pages/flight-compensation" */))
const _241688a8 = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _e9da6ae0 = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _3754c032 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _44bf61b7 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _25065080 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _dffa2e44 = () => interopDefault(import('../pages/internal/feedbacks.vue' /* webpackChunkName: "pages/internal/feedbacks" */))
const _1dd61aff = () => interopDefault(import('../pages/internal/generate-slug-types.vue' /* webpackChunkName: "pages/internal/generate-slug-types" */))
const _fe28651c = () => interopDefault(import('../pages/internal/load-content.vue' /* webpackChunkName: "pages/internal/load-content" */))
const _f1d09af6 = () => interopDefault(import('../pages/internal/sitemap.vue' /* webpackChunkName: "pages/internal/sitemap" */))
const _0dd264ad = () => interopDefault(import('../pages/partners/lawyers.vue' /* webpackChunkName: "pages/partners/lawyers" */))
const _71f822ea = () => interopDefault(import('../pages/partners/affiliate.vue' /* webpackChunkName: "pages/partners/affiliate" */))
const _5d18eebd = () => interopDefault(import('../pages/partners/refer-friend.vue' /* webpackChunkName: "pages/partners/refer-friend" */))
const _478e8254 = () => interopDefault(import('../pages/partners/travel-agency.vue' /* webpackChunkName: "pages/partners/travel-agency" */))
const _5a4a8cc8 = () => interopDefault(import('../pages/partners/refer-friend-tos.vue' /* webpackChunkName: "pages/partners/refer-friend-tos" */))
const _581614d4 = () => interopDefault(import('../pages/partners/affiliate-tos.vue' /* webpackChunkName: "pages/partners/affiliate-tos" */))
const _32127aab = () => interopDefault(import('../pages/user/affiliate.vue' /* webpackChunkName: "pages/user/affiliate" */))
const _e797ec72 = () => interopDefault(import('../pages/user/login.vue' /* webpackChunkName: "pages/user/login" */))
const _475e379c = () => interopDefault(import('../pages/user/logout.vue' /* webpackChunkName: "pages/user/logout" */))
const _3cb494a6 = () => interopDefault(import('../pages/user/password.vue' /* webpackChunkName: "pages/user/password" */))
const _4a858a87 = () => interopDefault(import('../pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _8cf556ee = () => interopDefault(import('../pages/user/sign-up.vue' /* webpackChunkName: "pages/user/sign-up" */))
const _1399e472 = () => interopDefault(import('../pages/user/dashboard.vue' /* webpackChunkName: "pages/user/dashboard" */))
const _501d677c = () => interopDefault(import('../pages/user/affiliate_payment_request.vue' /* webpackChunkName: "pages/user/affiliate_payment_request" */))
const _af409872 = () => interopDefault(import('../pages/user/affiliate_payment_history.vue' /* webpackChunkName: "pages/user/affiliate_payment_history" */))
const _577ba523 = () => interopDefault(import('../pages/user/affiliate_creative_material.vue' /* webpackChunkName: "pages/user/affiliate_creative_material" */))
const _4cce3604 = () => interopDefault(import('../pages/user/affiliate_payment_method.vue' /* webpackChunkName: "pages/user/affiliate_payment_method" */))
const _13a3b50d = () => interopDefault(import('../pages/user/affiliate_convert_account.vue' /* webpackChunkName: "pages/user/affiliate_convert_account" */))
const _d4de4592 = () => interopDefault(import('../pages/user/affiliate_activate.vue' /* webpackChunkName: "pages/user/affiliate_activate" */))
const _57862220 = () => interopDefault(import('../pages/user/travel-agency/claims.vue' /* webpackChunkName: "pages/user/travel-agency/claims" */))
const _781fbfbe = () => interopDefault(import('../pages/user/affiliate_refer_friend_activate.vue' /* webpackChunkName: "pages/user/affiliate_refer_friend_activate" */))
const _2ea536d2 = () => interopDefault(import('../pages/user/travel-agency/offline-form.vue' /* webpackChunkName: "pages/user/travel-agency/offline-form" */))
const _7806cfa2 = () => interopDefault(import('../pages/user/travel-agency/payment-request.vue' /* webpackChunkName: "pages/user/travel-agency/payment-request" */))
const _d72a0098 = () => interopDefault(import('../pages/user/travel-agency/payment-history.vue' /* webpackChunkName: "pages/user/travel-agency/payment-history" */))
const _520ade2f = () => interopDefault(import('../pages/user/travel-agency/upload-offline-claim.vue' /* webpackChunkName: "pages/user/travel-agency/upload-offline-claim" */))
const _62207262 = () => interopDefault(import('../pages/user/travel-agency/commissions.vue' /* webpackChunkName: "pages/user/travel-agency/commissions" */))
const _7fa4311e = () => interopDefault(import('../pages/user/travel-agency/payment-method.vue' /* webpackChunkName: "pages/user/travel-agency/payment-method" */))
const _1dfcbdca = () => interopDefault(import('../pages/user/travel-agency/offline.vue' /* webpackChunkName: "pages/user/travel-agency/offline" */))
const _6b1ccabc = () => interopDefault(import('../pages/user/travel-agency/online.vue' /* webpackChunkName: "pages/user/travel-agency/online" */))
const _329a23b6 = () => interopDefault(import('../pages/user/travel-agency/online-banners.vue' /* webpackChunkName: "pages/user/travel-agency/online-banners" */))
const _b073e014 = () => interopDefault(import('../pages/user/travel-agency/new-claim.vue' /* webpackChunkName: "pages/user/travel-agency/new-claim" */))
const _38d4d19f = () => interopDefault(import('../pages/user/travel-agency/edit-claim/_code.vue' /* webpackChunkName: "pages/user/travel-agency/edit-claim/_code" */))
const _3e662347 = () => interopDefault(import('../pages/user/travel-agency/status-claim/_code.vue' /* webpackChunkName: "pages/user/travel-agency/status-claim/_code" */))
const _553e399b = () => interopDefault(import('../pages/user/affiliate_complete_sign_up.vue' /* webpackChunkName: "pages/user/affiliate_complete_sign_up" */))
const _3b58408a = () => interopDefault(import('../pages/user/confirm_email.vue' /* webpackChunkName: "pages/user/confirm_email" */))
const _232f771d = () => interopDefault(import('../pages/user/reset_password.vue' /* webpackChunkName: "pages/user/reset_password" */))
const _1a51b329 = () => interopDefault(import('../pages/user/complete-sign-up.vue' /* webpackChunkName: "pages/user/complete-sign-up" */))
const _7ad9a7a2 = () => interopDefault(import('../pages/claim/sign-contract/_code/_indexes/_step.vue' /* webpackChunkName: "pages/claim/sign-contract/_code/_indexes/_step" */))
const _5ee7687f = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _355ca46f = () => interopDefault(import('../pages/info/_page.vue' /* webpackChunkName: "pages/info/_page" */))
const _5429fdec = () => interopDefault(import('../pages/lost-luggage/_code.vue' /* webpackChunkName: "pages/lost-luggage/_code" */))
const _0c338d98 = () => interopDefault(import('../pages/new-claim/_slug.vue' /* webpackChunkName: "pages/new-claim/_slug" */))
const _4aad1af1 = () => interopDefault(import('../pages/new-disruption-protection/_code.vue' /* webpackChunkName: "pages/new-disruption-protection/_code" */))
const _29563246 = () => interopDefault(import('../pages/compensation/_type.vue' /* webpackChunkName: "pages/compensation/_type" */))
const _1298700b = () => interopDefault(import('../pages/cancelled-flight/_code.vue' /* webpackChunkName: "pages/cancelled-flight/_code" */))
const _208126b4 = () => interopDefault(import('../pages/reviews/_code.vue' /* webpackChunkName: "pages/reviews/_code" */))
const _7feb1d7c = () => interopDefault(import('../pages/claim-partner/_code.vue' /* webpackChunkName: "pages/claim-partner/_code" */))
const _88a06b16 = () => interopDefault(import('../pages/faq/_category/index.vue' /* webpackChunkName: "pages/faq/_category/index" */))
const _4639ef52 = () => interopDefault(import('../pages/overbooking-flight/_code.vue' /* webpackChunkName: "pages/overbooking-flight/_code" */))
const _387dff0a = () => interopDefault(import('../pages/short-link/_code.vue' /* webpackChunkName: "pages/short-link/_code" */))
const _3a7b6a4e = () => interopDefault(import('../pages/status/_internalcode.vue' /* webpackChunkName: "pages/status/_internalcode" */))
const _138858d7 = () => interopDefault(import('../pages/bank-account/_code/_step.vue' /* webpackChunkName: "pages/bank-account/_code/_step" */))
const _5935d01f = () => interopDefault(import('../pages/documents/_code/_step.vue' /* webpackChunkName: "pages/documents/_code/_step" */))
const _3994c030 = () => interopDefault(import('../pages/faq/_category/_question.vue' /* webpackChunkName: "pages/faq/_category/_question" */))
const _5bad0df6 = () => interopDefault(import('../pages/legal-action/_code/_step.vue' /* webpackChunkName: "pages/legal-action/_code/_step" */))
const _2a6728a3 = () => interopDefault(import('../pages/claim/_code/_step.vue' /* webpackChunkName: "pages/claim/_code/_step" */))
const _36e8d5ea = () => interopDefault(import('../pages/insert-bank-account/_code/_security.vue' /* webpackChunkName: "pages/insert-bank-account/_code/_security" */))
const _8d1a10f8 = () => interopDefault(import('../pages/root.vue' /* webpackChunkName: "pages/root" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bg",
    component: _7b17bea0,
    name: "index___bg"
  }, {
    path: "/cs",
    component: _7b17bea0,
    name: "index___cs"
  }, {
    path: "/de",
    component: _7b17bea0,
    name: "index___de"
  }, {
    path: "/en",
    component: _7b17bea0,
    name: "index___en"
  }, {
    path: "/es",
    component: _7b17bea0,
    name: "index___es"
  }, {
    path: "/hu",
    component: _7b17bea0,
    name: "index___hu"
  }, {
    path: "/it",
    component: _7b17bea0,
    name: "index___it"
  }, {
    path: "/pl",
    component: _7b17bea0,
    name: "index___pl"
  }, {
    path: "/pt",
    component: _7b17bea0,
    name: "index___pt"
  }, {
    path: "/ro",
    component: _7b17bea0,
    name: "index___ro"
  }, {
    path: "/ru",
    component: _7b17bea0,
    name: "index___ru"
  }, {
    path: "/sq",
    component: _7b17bea0,
    name: "index___sq"
  }, {
    path: "/bg/blog",
    component: _609e09c7,
    name: "blog___bg"
  }, {
    path: "/bg/currency-rate",
    component: _3982abea,
    name: "currency-rate___bg"
  }, {
    path: "/bg/kontakt",
    component: _05732d6e,
    name: "contact___bg"
  }, {
    path: "/bg/korporativni",
    component: _99f0dc9a,
    name: "corporate___bg"
  }, {
    path: "/bg/obezshteteniye",
    component: _71a1cf65,
    name: "flight-compensation___bg"
  }, {
    path: "/bg/otzivi",
    component: _241688a8,
    name: "reviews___bg"
  }, {
    path: "/bg/partneri",
    component: _e9da6ae0,
    name: "partners___bg"
  }, {
    path: "/bg/pomosht",
    component: _3754c032,
    name: "faq___bg"
  }, {
    path: "/bg/vhod",
    component: _44bf61b7,
    name: "login___bg"
  }, {
    path: "/bg/za-nas",
    component: _25065080,
    name: "about-us___bg"
  }, {
    path: "/cs/blog",
    component: _609e09c7,
    name: "blog___cs"
  }, {
    path: "/cs/currency-rate",
    component: _3982abea,
    name: "currency-rate___cs"
  }, {
    path: "/cs/kompenzace",
    component: _71a1cf65,
    name: "flight-compensation___cs"
  }, {
    path: "/cs/kontakt",
    component: _05732d6e,
    name: "contact___cs"
  }, {
    path: "/cs/korporatni",
    component: _99f0dc9a,
    name: "corporate___cs"
  }, {
    path: "/cs/o-nas",
    component: _25065080,
    name: "about-us___cs"
  }, {
    path: "/cs/partneri",
    component: _e9da6ae0,
    name: "partners___cs"
  }, {
    path: "/cs/pomoc",
    component: _3754c032,
    name: "faq___cs"
  }, {
    path: "/cs/prihlaseni",
    component: _44bf61b7,
    name: "login___cs"
  }, {
    path: "/cs/recenze",
    component: _241688a8,
    name: "reviews___cs"
  }, {
    path: "/de/anmeldung",
    component: _44bf61b7,
    name: "login___de"
  }, {
    path: "/de/bewertungen",
    component: _241688a8,
    name: "reviews___de"
  }, {
    path: "/de/blog",
    component: _609e09c7,
    name: "blog___de"
  }, {
    path: "/de/currency-rate",
    component: _3982abea,
    name: "currency-rate___de"
  }, {
    path: "/de/entschadigung",
    component: _71a1cf65,
    name: "flight-compensation___de"
  }, {
    path: "/de/hilfe",
    component: _3754c032,
    name: "faq___de"
  }, {
    path: "/de/kontakt",
    component: _05732d6e,
    name: "contact___de"
  }, {
    path: "/de/partner",
    component: _e9da6ae0,
    name: "partners___de"
  }, {
    path: "/de/uber-uns",
    component: _25065080,
    name: "about-us___de"
  }, {
    path: "/de/unternehmen",
    component: _99f0dc9a,
    name: "corporate___de"
  }, {
    path: "/en/about-us",
    component: _25065080,
    name: "about-us___en"
  }, {
    path: "/en/blog",
    component: _609e09c7,
    name: "blog___en"
  }, {
    path: "/en/compensation",
    component: _71a1cf65,
    name: "flight-compensation___en"
  }, {
    path: "/en/contact",
    component: _05732d6e,
    name: "contact___en"
  }, {
    path: "/en/corporate",
    component: _99f0dc9a,
    name: "corporate___en"
  }, {
    path: "/en/currency-rate",
    component: _3982abea,
    name: "currency-rate___en"
  }, {
    path: "/en/help",
    component: _3754c032,
    name: "faq___en"
  }, {
    path: "/en/login",
    component: _44bf61b7,
    name: "login___en"
  }, {
    path: "/en/partners",
    component: _e9da6ae0,
    name: "partners___en"
  }, {
    path: "/en/reviews",
    component: _241688a8,
    name: "reviews___en"
  }, {
    path: "/es/accede",
    component: _44bf61b7,
    name: "login___es"
  }, {
    path: "/es/ayuda",
    component: _3754c032,
    name: "faq___es"
  }, {
    path: "/es/blog",
    component: _609e09c7,
    name: "blog___es"
  }, {
    path: "/es/colaboradores",
    component: _e9da6ae0,
    name: "partners___es"
  }, {
    path: "/es/contacto",
    component: _05732d6e,
    name: "contact___es"
  }, {
    path: "/es/corporativo",
    component: _99f0dc9a,
    name: "corporate___es"
  }, {
    path: "/es/currency-rate",
    component: _3982abea,
    name: "currency-rate___es"
  }, {
    path: "/es/indemnizacion",
    component: _71a1cf65,
    name: "flight-compensation___es"
  }, {
    path: "/es/resenas",
    component: _241688a8,
    name: "reviews___es"
  }, {
    path: "/es/sobre-nosotros",
    component: _25065080,
    name: "about-us___es"
  }, {
    path: "/hu/bejelentkezes",
    component: _44bf61b7,
    name: "login___hu"
  }, {
    path: "/hu/blog",
    component: _609e09c7,
    name: "blog___hu"
  }, {
    path: "/hu/currency-rate",
    component: _3982abea,
    name: "currency-rate___hu"
  }, {
    path: "/hu/kapcsolatfelvetel",
    component: _05732d6e,
    name: "contact___hu"
  }, {
    path: "/hu/kompenzacio",
    component: _71a1cf65,
    name: "flight-compensation___hu"
  }, {
    path: "/hu/partnerek",
    component: _e9da6ae0,
    name: "partners___hu"
  }, {
    path: "/hu/rolunk",
    component: _25065080,
    name: "about-us___hu"
  }, {
    path: "/hu/segitseg",
    component: _3754c032,
    name: "faq___hu"
  }, {
    path: "/hu/vallalati",
    component: _99f0dc9a,
    name: "corporate___hu"
  }, {
    path: "/hu/velemenyek",
    component: _241688a8,
    name: "reviews___hu"
  }, {
    path: "/it/accesso",
    component: _44bf61b7,
    name: "login___it"
  }, {
    path: "/it/aiuto",
    component: _3754c032,
    name: "faq___it"
  }, {
    path: "/it/aziende",
    component: _99f0dc9a,
    name: "corporate___it"
  }, {
    path: "/it/blog",
    component: _609e09c7,
    name: "blog___it"
  }, {
    path: "/it/chi-siamo",
    component: _25065080,
    name: "about-us___it"
  }, {
    path: "/it/contatti",
    component: _05732d6e,
    name: "contact___it"
  }, {
    path: "/it/currency-rate",
    component: _3982abea,
    name: "currency-rate___it"
  }, {
    path: "/it/partners",
    component: _e9da6ae0,
    name: "partners___it"
  }, {
    path: "/it/recensioni",
    component: _241688a8,
    name: "reviews___it"
  }, {
    path: "/it/risarcimento",
    component: _71a1cf65,
    name: "flight-compensation___it"
  }, {
    path: "/pl/blog",
    component: _609e09c7,
    name: "blog___pl"
  }, {
    path: "/pl/currency-rate",
    component: _3982abea,
    name: "currency-rate___pl"
  }, {
    path: "/pl/kontakt",
    component: _05732d6e,
    name: "contact___pl"
  }, {
    path: "/pl/korporacyjny",
    component: _99f0dc9a,
    name: "corporate___pl"
  }, {
    path: "/pl/logowanie",
    component: _44bf61b7,
    name: "login___pl"
  }, {
    path: "/pl/o-nas",
    component: _25065080,
    name: "about-us___pl"
  }, {
    path: "/pl/odszkodowanie",
    component: _71a1cf65,
    name: "flight-compensation___pl"
  }, {
    path: "/pl/partnerzy",
    component: _e9da6ae0,
    name: "partners___pl"
  }, {
    path: "/pl/pomoc",
    component: _3754c032,
    name: "faq___pl"
  }, {
    path: "/pl/recenzje",
    component: _241688a8,
    name: "reviews___pl"
  }, {
    path: "/pt/acesso",
    component: _44bf61b7,
    name: "login___pt"
  }, {
    path: "/pt/ajuda",
    component: _3754c032,
    name: "faq___pt"
  }, {
    path: "/pt/avaliacoes",
    component: _241688a8,
    name: "reviews___pt"
  }, {
    path: "/pt/blog",
    component: _609e09c7,
    name: "blog___pt"
  }, {
    path: "/pt/compensacao",
    component: _71a1cf65,
    name: "flight-compensation___pt"
  }, {
    path: "/pt/contato",
    component: _05732d6e,
    name: "contact___pt"
  }, {
    path: "/pt/corporativo",
    component: _99f0dc9a,
    name: "corporate___pt"
  }, {
    path: "/pt/currency-rate",
    component: _3982abea,
    name: "currency-rate___pt"
  }, {
    path: "/pt/parceiros",
    component: _e9da6ae0,
    name: "partners___pt"
  }, {
    path: "/pt/sobre-nos",
    component: _25065080,
    name: "about-us___pt"
  }, {
    path: "/ro/ajutor",
    component: _3754c032,
    name: "faq___ro"
  }, {
    path: "/ro/blog",
    component: _609e09c7,
    name: "blog___ro"
  }, {
    path: "/ro/contact",
    component: _05732d6e,
    name: "contact___ro"
  }, {
    path: "/ro/corporativ",
    component: _99f0dc9a,
    name: "corporate___ro"
  }, {
    path: "/ro/currency-rate",
    component: _3982abea,
    name: "currency-rate___ro"
  }, {
    path: "/ro/despagubire",
    component: _71a1cf65,
    name: "flight-compensation___ro"
  }, {
    path: "/ro/despre-noi",
    component: _25065080,
    name: "about-us___ro"
  }, {
    path: "/ro/logare",
    component: _44bf61b7,
    name: "login___ro"
  }, {
    path: "/ro/partners",
    component: _e9da6ae0,
    name: "partners___ro"
  }, {
    path: "/ro/recenzii",
    component: _241688a8,
    name: "reviews___ro"
  }, {
    path: "/ru/blog",
    component: _609e09c7,
    name: "blog___ru"
  }, {
    path: "/ru/currency-rate",
    component: _3982abea,
    name: "currency-rate___ru"
  }, {
    path: "/ru/kompensatsiya",
    component: _71a1cf65,
    name: "flight-compensation___ru"
  }, {
    path: "/ru/kontakt",
    component: _05732d6e,
    name: "contact___ru"
  }, {
    path: "/ru/korporativnyy",
    component: _99f0dc9a,
    name: "corporate___ru"
  }, {
    path: "/ru/login",
    component: _44bf61b7,
    name: "login___ru"
  }, {
    path: "/ru/o-nas",
    component: _25065080,
    name: "about-us___ru"
  }, {
    path: "/ru/otzyvy",
    component: _241688a8,
    name: "reviews___ru"
  }, {
    path: "/ru/partners",
    component: _e9da6ae0,
    name: "partners___ru"
  }, {
    path: "/ru/pomoshch",
    component: _3754c032,
    name: "faq___ru"
  }, {
    path: "/sq/blog",
    component: _609e09c7,
    name: "blog___sq"
  }, {
    path: "/sq/currency-rate",
    component: _3982abea,
    name: "currency-rate___sq"
  }, {
    path: "/sq/hyrje",
    component: _44bf61b7,
    name: "login___sq"
  }, {
    path: "/sq/kompensim",
    component: _71a1cf65,
    name: "flight-compensation___sq"
  }, {
    path: "/sq/kontakt",
    component: _05732d6e,
    name: "contact___sq"
  }, {
    path: "/sq/korporativ",
    component: _99f0dc9a,
    name: "corporate___sq"
  }, {
    path: "/sq/ndihme",
    component: _3754c032,
    name: "faq___sq"
  }, {
    path: "/sq/partneret",
    component: _e9da6ae0,
    name: "partners___sq"
  }, {
    path: "/sq/rishikimet",
    component: _241688a8,
    name: "reviews___sq"
  }, {
    path: "/sq/rreth-nesh",
    component: _25065080,
    name: "about-us___sq"
  }, {
    path: "/bg/internal/feedbacks",
    component: _dffa2e44,
    name: "internal-feedbacks___bg"
  }, {
    path: "/bg/internal/generate-slug-types",
    component: _1dd61aff,
    name: "internal-generate-slug-types___bg"
  }, {
    path: "/bg/internal/load-content",
    component: _fe28651c,
    name: "internal-load-content___bg"
  }, {
    path: "/bg/internal/sitemap",
    component: _f1d09af6,
    name: "internal-sitemap___bg"
  }, {
    path: "/bg/partneri/advokatska-kantora",
    component: _0dd264ad,
    name: "partners-lawyers___bg"
  }, {
    path: "/bg/partneri/afiliatsiya",
    component: _71f822ea,
    name: "partners-affiliate___bg"
  }, {
    path: "/bg/partneri/preporachai-priyatel",
    component: _5d18eebd,
    name: "partners-refer-friend___bg"
  }, {
    path: "/bg/partneri/turisticheska-agentsiya",
    component: _478e8254,
    name: "partners-travel-agency___bg"
  }, {
    path: "/bg/partneri/usloviya-i-pravila-preporachai-priyatel",
    component: _5a4a8cc8,
    name: "partners-refer-friend-tos___bg"
  }, {
    path: "/bg/partnyori/uslovia-na-programata-za-partnyorstvo",
    component: _581614d4,
    name: "partners-affiliate-tos___bg"
  }, {
    path: "/bg/potrebitel/affiliate",
    component: _32127aab,
    name: "user-affiliate___bg"
  }, {
    path: "/bg/potrebitel/dostap",
    component: _e797ec72,
    name: "user-login___bg"
  }, {
    path: "/bg/potrebitel/izhod",
    component: _475e379c,
    name: "user-logout___bg"
  }, {
    path: "/bg/potrebitel/parola",
    component: _3cb494a6,
    name: "user-password___bg"
  }, {
    path: "/bg/potrebitel/profil",
    component: _4a858a87,
    name: "user-profile___bg"
  }, {
    path: "/bg/potrebitel/registratsiya",
    component: _8cf556ee,
    name: "user-sign-up___bg"
  }, {
    path: "/bg/potrebitel/tablo",
    component: _1399e472,
    name: "user-dashboard___bg"
  }, {
    path: "/cs/internal/feedbacks",
    component: _dffa2e44,
    name: "internal-feedbacks___cs"
  }, {
    path: "/cs/internal/generate-slug-types",
    component: _1dd61aff,
    name: "internal-generate-slug-types___cs"
  }, {
    path: "/cs/internal/load-content",
    component: _fe28651c,
    name: "internal-load-content___cs"
  }, {
    path: "/cs/internal/sitemap",
    component: _f1d09af6,
    name: "internal-sitemap___cs"
  }, {
    path: "/cs/partneri/afilace",
    component: _71f822ea,
    name: "partners-affiliate___cs"
  }, {
    path: "/cs/partneri/cestovni-kancelar",
    component: _478e8254,
    name: "partners-travel-agency___cs"
  }, {
    path: "/cs/partneri/doporuceni-pratele",
    component: _5d18eebd,
    name: "partners-refer-friend___cs"
  }, {
    path: "/cs/partneri/podminky-partnerskeho-programu",
    component: _581614d4,
    name: "partners-affiliate-tos___cs"
  }, {
    path: "/cs/partneri/podminky-pravidla-doporuceni-pratele",
    component: _5a4a8cc8,
    name: "partners-refer-friend-tos___cs"
  }, {
    path: "/cs/partneri/pravnicka-firma",
    component: _0dd264ad,
    name: "partners-lawyers___cs"
  }, {
    path: "/cs/uzivatel/affiliate",
    component: _32127aab,
    name: "user-affiliate___cs"
  }, {
    path: "/cs/uzivatel/heslo",
    component: _3cb494a6,
    name: "user-password___cs"
  }, {
    path: "/cs/uzivatel/odhlasit",
    component: _475e379c,
    name: "user-logout___cs"
  }, {
    path: "/cs/uzivatel/panel",
    component: _1399e472,
    name: "user-dashboard___cs"
  }, {
    path: "/cs/uzivatel/prihlaseni",
    component: _e797ec72,
    name: "user-login___cs"
  }, {
    path: "/cs/uzivatel/profil",
    component: _4a858a87,
    name: "user-profile___cs"
  }, {
    path: "/cs/uzivatel/registrace",
    component: _8cf556ee,
    name: "user-sign-up___cs"
  }, {
    path: "/de/benutzer/abmelden",
    component: _475e379c,
    name: "user-logout___de"
  }, {
    path: "/de/benutzer/affiliate",
    component: _32127aab,
    name: "user-affiliate___de"
  }, {
    path: "/de/benutzer/dashboard",
    component: _1399e472,
    name: "user-dashboard___de"
  }, {
    path: "/de/benutzer/passwort",
    component: _3cb494a6,
    name: "user-password___de"
  }, {
    path: "/de/benutzer/profil",
    component: _4a858a87,
    name: "user-profile___de"
  }, {
    path: "/de/benutzer/registrieren",
    component: _8cf556ee,
    name: "user-sign-up___de"
  }, {
    path: "/de/benutzer/zugang",
    component: _e797ec72,
    name: "user-login___de"
  }, {
    path: "/de/internal/feedbacks",
    component: _dffa2e44,
    name: "internal-feedbacks___de"
  }, {
    path: "/de/internal/generate-slug-types",
    component: _1dd61aff,
    name: "internal-generate-slug-types___de"
  }, {
    path: "/de/internal/load-content",
    component: _fe28651c,
    name: "internal-load-content___de"
  }, {
    path: "/de/internal/sitemap",
    component: _f1d09af6,
    name: "internal-sitemap___de"
  }, {
    path: "/de/partner/affiliation",
    component: _71f822ea,
    name: "partners-affiliate___de"
  }, {
    path: "/de/partner/bedingungen-und-konditionen-freund-werben",
    component: _5a4a8cc8,
    name: "partners-refer-friend-tos___de"
  }, {
    path: "/de/partner/freund-werben",
    component: _5d18eebd,
    name: "partners-refer-friend___de"
  }, {
    path: "/de/partner/kanzlei",
    component: _0dd264ad,
    name: "partners-lawyers___de"
  }, {
    path: "/de/partner/reisebuero",
    component: _478e8254,
    name: "partners-travel-agency___de"
  }, {
    path: "/de/partnerprogramm/affiliate-programm-bedingungen",
    component: _581614d4,
    name: "partners-affiliate-tos___de"
  }, {
    path: "/en/internal/feedbacks",
    component: _dffa2e44,
    name: "internal-feedbacks___en"
  }, {
    path: "/en/internal/generate-slug-types",
    component: _1dd61aff,
    name: "internal-generate-slug-types___en"
  }, {
    path: "/en/internal/load-content",
    component: _fe28651c,
    name: "internal-load-content___en"
  }, {
    path: "/en/internal/sitemap",
    component: _f1d09af6,
    name: "internal-sitemap___en"
  }, {
    path: "/en/partners/affiliate-program-terms-and-conditions",
    component: _581614d4,
    name: "partners-affiliate-tos___en"
  }, {
    path: "/en/partners/affiliation",
    component: _71f822ea,
    name: "partners-affiliate___en"
  }, {
    path: "/en/partners/friend-refer",
    component: _5d18eebd,
    name: "partners-refer-friend___en"
  }, {
    path: "/en/partners/law-firm",
    component: _0dd264ad,
    name: "partners-lawyers___en"
  }, {
    path: "/en/partners/terms-conditions-friend-refer",
    component: _5a4a8cc8,
    name: "partners-refer-friend-tos___en"
  }, {
    path: "/en/partners/travel-agency",
    component: _478e8254,
    name: "partners-travel-agency___en"
  }, {
    path: "/en/user/affiliate",
    component: _32127aab,
    name: "user-affiliate___en"
  }, {
    path: "/en/user/dashboard",
    component: _1399e472,
    name: "user-dashboard___en"
  }, {
    path: "/en/user/login",
    component: _e797ec72,
    name: "user-login___en"
  }, {
    path: "/en/user/logout",
    component: _475e379c,
    name: "user-logout___en"
  }, {
    path: "/en/user/password",
    component: _3cb494a6,
    name: "user-password___en"
  }, {
    path: "/en/user/profile",
    component: _4a858a87,
    name: "user-profile___en"
  }, {
    path: "/en/user/sign-up",
    component: _8cf556ee,
    name: "user-sign-up___en"
  }, {
    path: "/es/colaboradores/afiliacion",
    component: _71f822ea,
    name: "partners-affiliate___es"
  }, {
    path: "/es/colaboradores/agencia-de-viajes",
    component: _478e8254,
    name: "partners-travel-agency___es"
  }, {
    path: "/es/colaboradores/bufete-de-abogados",
    component: _0dd264ad,
    name: "partners-lawyers___es"
  }, {
    path: "/es/colaboradores/recomienda-a-un-amigo",
    component: _5d18eebd,
    name: "partners-refer-friend___es"
  }, {
    path: "/es/colaboradores/terminos-y-condiciones-del-programa-de-afiliados",
    component: _581614d4,
    name: "partners-affiliate-tos___es"
  }, {
    path: "/es/colaboradores/terminos-y-condiciones-recomienda-a-un-amigo",
    component: _5a4a8cc8,
    name: "partners-refer-friend-tos___es"
  }, {
    path: "/es/internal/feedbacks",
    component: _dffa2e44,
    name: "internal-feedbacks___es"
  }, {
    path: "/es/internal/generate-slug-types",
    component: _1dd61aff,
    name: "internal-generate-slug-types___es"
  }, {
    path: "/es/internal/load-content",
    component: _fe28651c,
    name: "internal-load-content___es"
  }, {
    path: "/es/internal/sitemap",
    component: _f1d09af6,
    name: "internal-sitemap___es"
  }, {
    path: "/es/usuario/acceso",
    component: _e797ec72,
    name: "user-login___es"
  }, {
    path: "/es/usuario/afiliado",
    component: _32127aab,
    name: "user-affiliate___es"
  }, {
    path: "/es/usuario/contrasena",
    component: _3cb494a6,
    name: "user-password___es"
  }, {
    path: "/es/usuario/inscripcion",
    component: _8cf556ee,
    name: "user-sign-up___es"
  }, {
    path: "/es/usuario/perfil",
    component: _4a858a87,
    name: "user-profile___es"
  }, {
    path: "/es/usuario/salida",
    component: _475e379c,
    name: "user-logout___es"
  }, {
    path: "/es/usuario/tablero",
    component: _1399e472,
    name: "user-dashboard___es"
  }, {
    path: "/hu/felhasznalo/affiliate",
    component: _32127aab,
    name: "user-affiliate___hu"
  }, {
    path: "/hu/felhasznalo/hozzaferes",
    component: _e797ec72,
    name: "user-login___hu"
  }, {
    path: "/hu/felhasznalo/iranyitoszam",
    component: _1399e472,
    name: "user-dashboard___hu"
  }, {
    path: "/hu/felhasznalo/jelszo",
    component: _3cb494a6,
    name: "user-password___hu"
  }, {
    path: "/hu/felhasznalo/kijelentkezes",
    component: _475e379c,
    name: "user-logout___hu"
  }, {
    path: "/hu/felhasznalo/profil",
    component: _4a858a87,
    name: "user-profile___hu"
  }, {
    path: "/hu/felhasznalo/regisztracio",
    component: _8cf556ee,
    name: "user-sign-up___hu"
  }, {
    path: "/hu/internal/feedbacks",
    component: _dffa2e44,
    name: "internal-feedbacks___hu"
  }, {
    path: "/hu/internal/generate-slug-types",
    component: _1dd61aff,
    name: "internal-generate-slug-types___hu"
  }, {
    path: "/hu/internal/load-content",
    component: _fe28651c,
    name: "internal-load-content___hu"
  }, {
    path: "/hu/internal/sitemap",
    component: _f1d09af6,
    name: "internal-sitemap___hu"
  }, {
    path: "/hu/partnerek/affiliate-program-feltetelei",
    component: _581614d4,
    name: "partners-affiliate-tos___hu"
  }, {
    path: "/hu/partnerek/barat-ajanlasa",
    component: _5d18eebd,
    name: "partners-refer-friend___hu"
  }, {
    path: "/hu/partnerek/feltetelek-szabalyok-barat-ajanlasa",
    component: _5a4a8cc8,
    name: "partners-refer-friend-tos___hu"
  }, {
    path: "/hu/partnerek/jogiroda",
    component: _0dd264ad,
    name: "partners-lawyers___hu"
  }, {
    path: "/hu/partnerek/utazasi-iroda",
    component: _478e8254,
    name: "partners-travel-agency___hu"
  }, {
    path: "/hu/partnersag/affiliacio",
    component: _71f822ea,
    name: "partners-affiliate___hu"
  }, {
    path: "/it/internal/feedbacks",
    component: _dffa2e44,
    name: "internal-feedbacks___it"
  }, {
    path: "/it/internal/generate-slug-types",
    component: _1dd61aff,
    name: "internal-generate-slug-types___it"
  }, {
    path: "/it/internal/load-content",
    component: _fe28651c,
    name: "internal-load-content___it"
  }, {
    path: "/it/internal/sitemap",
    component: _f1d09af6,
    name: "internal-sitemap___it"
  }, {
    path: "/it/partners/affiliazione",
    component: _71f822ea,
    name: "partners-affiliate___it"
  }, {
    path: "/it/partners/agenzia-viaggio",
    component: _478e8254,
    name: "partners-travel-agency___it"
  }, {
    path: "/it/partners/segnala-un-amico",
    component: _5d18eebd,
    name: "partners-refer-friend___it"
  }, {
    path: "/it/partners/studio-legale",
    component: _0dd264ad,
    name: "partners-lawyers___it"
  }, {
    path: "/it/partners/termini-e-condizioni-programma-di-affiliazione",
    component: _581614d4,
    name: "partners-affiliate-tos___it"
  }, {
    path: "/it/partners/termini-e-condizioni-segnala-un-amico",
    component: _5a4a8cc8,
    name: "partners-refer-friend-tos___it"
  }, {
    path: "/it/utente/accesso",
    component: _e797ec72,
    name: "user-login___it"
  }, {
    path: "/it/utente/affiliato",
    component: _32127aab,
    name: "user-affiliate___it"
  }, {
    path: "/it/utente/dashboard",
    component: _1399e472,
    name: "user-dashboard___it"
  }, {
    path: "/it/utente/password",
    component: _3cb494a6,
    name: "user-password___it"
  }, {
    path: "/it/utente/profilo",
    component: _4a858a87,
    name: "user-profile___it"
  }, {
    path: "/it/utente/registrazione",
    component: _8cf556ee,
    name: "user-sign-up___it"
  }, {
    path: "/it/utente/uscita",
    component: _475e379c,
    name: "user-logout___it"
  }, {
    path: "/pl/internal/feedbacks",
    component: _dffa2e44,
    name: "internal-feedbacks___pl"
  }, {
    path: "/pl/internal/generate-slug-types",
    component: _1dd61aff,
    name: "internal-generate-slug-types___pl"
  }, {
    path: "/pl/internal/load-content",
    component: _fe28651c,
    name: "internal-load-content___pl"
  }, {
    path: "/pl/internal/sitemap",
    component: _f1d09af6,
    name: "internal-sitemap___pl"
  }, {
    path: "/pl/partnerzy/afiliacja",
    component: _71f822ea,
    name: "partners-affiliate___pl"
  }, {
    path: "/pl/partnerzy/biuro-podrozy",
    component: _478e8254,
    name: "partners-travel-agency___pl"
  }, {
    path: "/pl/partnerzy/kancelaria-prawnicza",
    component: _0dd264ad,
    name: "partners-lawyers___pl"
  }, {
    path: "/pl/partnerzy/polec-znajomego",
    component: _5d18eebd,
    name: "partners-refer-friend___pl"
  }, {
    path: "/pl/partnerzy/regulamin-programu-partnerskiego",
    component: _581614d4,
    name: "partners-affiliate-tos___pl"
  }, {
    path: "/pl/partnerzy/warunki-zasady-polecenia-znajomego",
    component: _5a4a8cc8,
    name: "partners-refer-friend-tos___pl"
  }, {
    path: "/pl/uzytkownik/affiliate",
    component: _32127aab,
    name: "user-affiliate___pl"
  }, {
    path: "/pl/uzytkownik/dostep",
    component: _e797ec72,
    name: "user-login___pl"
  }, {
    path: "/pl/uzytkownik/haslo",
    component: _3cb494a6,
    name: "user-password___pl"
  }, {
    path: "/pl/uzytkownik/panel",
    component: _1399e472,
    name: "user-dashboard___pl"
  }, {
    path: "/pl/uzytkownik/profil",
    component: _4a858a87,
    name: "user-profile___pl"
  }, {
    path: "/pl/uzytkownik/rejestracja",
    component: _8cf556ee,
    name: "user-sign-up___pl"
  }, {
    path: "/pl/uzytkownik/wyloguj",
    component: _475e379c,
    name: "user-logout___pl"
  }, {
    path: "/pt/internal/feedbacks",
    component: _dffa2e44,
    name: "internal-feedbacks___pt"
  }, {
    path: "/pt/internal/generate-slug-types",
    component: _1dd61aff,
    name: "internal-generate-slug-types___pt"
  }, {
    path: "/pt/internal/load-content",
    component: _fe28651c,
    name: "internal-load-content___pt"
  }, {
    path: "/pt/internal/sitemap",
    component: _f1d09af6,
    name: "internal-sitemap___pt"
  }, {
    path: "/pt/parceiros/afiliacao",
    component: _71f822ea,
    name: "partners-affiliate___pt"
  }, {
    path: "/pt/parceiros/agencia-de-viagens",
    component: _478e8254,
    name: "partners-travel-agency___pt"
  }, {
    path: "/pt/parceiros/escritorio-de-advocacia",
    component: _0dd264ad,
    name: "partners-lawyers___pt"
  }, {
    path: "/pt/parceiros/referir-um-amigo",
    component: _5d18eebd,
    name: "partners-refer-friend___pt"
  }, {
    path: "/pt/parceiros/termos-condicoes-referir-um-amigo",
    component: _5a4a8cc8,
    name: "partners-refer-friend-tos___pt"
  }, {
    path: "/pt/parceiros/termos-e-condicoes-do-programa-de-afiliados",
    component: _581614d4,
    name: "partners-affiliate-tos___pt"
  }, {
    path: "/pt/usuario/acesso",
    component: _e797ec72,
    name: "user-login___pt"
  }, {
    path: "/pt/usuario/afiliado",
    component: _32127aab,
    name: "user-affiliate___pt"
  }, {
    path: "/pt/usuario/cadastro",
    component: _8cf556ee,
    name: "user-sign-up___pt"
  }, {
    path: "/pt/usuario/painel",
    component: _1399e472,
    name: "user-dashboard___pt"
  }, {
    path: "/pt/usuario/perfil",
    component: _4a858a87,
    name: "user-profile___pt"
  }, {
    path: "/pt/usuario/saida",
    component: _475e379c,
    name: "user-logout___pt"
  }, {
    path: "/pt/usuario/senha",
    component: _3cb494a6,
    name: "user-password___pt"
  }, {
    path: "/ro/internal/feedbacks",
    component: _dffa2e44,
    name: "internal-feedbacks___ro"
  }, {
    path: "/ro/internal/generate-slug-types",
    component: _1dd61aff,
    name: "internal-generate-slug-types___ro"
  }, {
    path: "/ro/internal/load-content",
    component: _fe28651c,
    name: "internal-load-content___ro"
  }, {
    path: "/ro/internal/sitemap",
    component: _f1d09af6,
    name: "internal-sitemap___ro"
  }, {
    path: "/ro/partners/afiliere",
    component: _71f822ea,
    name: "partners-affiliate___ro"
  }, {
    path: "/ro/partners/agentie-de-turism",
    component: _478e8254,
    name: "partners-travel-agency___ro"
  }, {
    path: "/ro/partners/firma-de-avocatura",
    component: _0dd264ad,
    name: "partners-lawyers___ro"
  }, {
    path: "/ro/partners/recomanda-un-prieten",
    component: _5d18eebd,
    name: "partners-refer-friend___ro"
  }, {
    path: "/ro/partners/termeni-si-conditii-programul-de-afiliere",
    component: _581614d4,
    name: "partners-affiliate-tos___ro"
  }, {
    path: "/ro/partners/termeni-si-conditii-recomanda-un-prieten",
    component: _5a4a8cc8,
    name: "partners-refer-friend-tos___ro"
  }, {
    path: "/ro/user/affiliate",
    component: _32127aab,
    name: "user-affiliate___ro"
  }, {
    path: "/ro/utilizator/acces",
    component: _e797ec72,
    name: "user-login___ro"
  }, {
    path: "/ro/utilizator/dashboard",
    component: _1399e472,
    name: "user-dashboard___ro"
  }, {
    path: "/ro/utilizator/deconectare",
    component: _475e379c,
    name: "user-logout___ro"
  }, {
    path: "/ro/utilizator/inscrie-te",
    component: _8cf556ee,
    name: "user-sign-up___ro"
  }, {
    path: "/ro/utilizator/parola",
    component: _3cb494a6,
    name: "user-password___ro"
  }, {
    path: "/ro/utilizator/profil",
    component: _4a858a87,
    name: "user-profile___ro"
  }, {
    path: "/ru/internal/feedbacks",
    component: _dffa2e44,
    name: "internal-feedbacks___ru"
  }, {
    path: "/ru/internal/generate-slug-types",
    component: _1dd61aff,
    name: "internal-generate-slug-types___ru"
  }, {
    path: "/ru/internal/load-content",
    component: _fe28651c,
    name: "internal-load-content___ru"
  }, {
    path: "/ru/internal/sitemap",
    component: _f1d09af6,
    name: "internal-sitemap___ru"
  }, {
    path: "/ru/partners/afilatsiya",
    component: _71f822ea,
    name: "partners-affiliate___ru"
  }, {
    path: "/ru/partners/rekomendatsiya-druga",
    component: _5d18eebd,
    name: "partners-refer-friend___ru"
  }, {
    path: "/ru/partners/turisticheskoe-agentstvo",
    component: _478e8254,
    name: "partners-travel-agency___ru"
  }, {
    path: "/ru/partners/usloviya-i-pravila-rekomendatsii-druzyam",
    component: _5a4a8cc8,
    name: "partners-refer-friend-tos___ru"
  }, {
    path: "/ru/partners/usloviya-programmy-partnerstva",
    component: _581614d4,
    name: "partners-affiliate-tos___ru"
  }, {
    path: "/ru/partners/yuridicheskaya-firma",
    component: _0dd264ad,
    name: "partners-lawyers___ru"
  }, {
    path: "/ru/polzovatel/dostup",
    component: _e797ec72,
    name: "user-login___ru"
  }, {
    path: "/ru/polzovatel/panel-instrumentov",
    component: _1399e472,
    name: "user-dashboard___ru"
  }, {
    path: "/ru/polzovatel/parol",
    component: _3cb494a6,
    name: "user-password___ru"
  }, {
    path: "/ru/polzovatel/profil",
    component: _4a858a87,
    name: "user-profile___ru"
  }, {
    path: "/ru/polzovatel/vyhod-iz-sistemy",
    component: _475e379c,
    name: "user-logout___ru"
  }, {
    path: "/ru/polzovatel/zaregistrirovatsya",
    component: _8cf556ee,
    name: "user-sign-up___ru"
  }, {
    path: "/ru/user/affiliate",
    component: _32127aab,
    name: "user-affiliate___ru"
  }, {
    path: "/sq/internal/feedbacks",
    component: _dffa2e44,
    name: "internal-feedbacks___sq"
  }, {
    path: "/sq/internal/generate-slug-types",
    component: _1dd61aff,
    name: "internal-generate-slug-types___sq"
  }, {
    path: "/sq/internal/load-content",
    component: _fe28651c,
    name: "internal-load-content___sq"
  }, {
    path: "/sq/internal/sitemap",
    component: _f1d09af6,
    name: "internal-sitemap___sq"
  }, {
    path: "/sq/partnere/termet-dhe-kushtet-e-programit-te-affiliates",
    component: _581614d4,
    name: "partners-affiliate-tos___sq"
  }, {
    path: "/sq/partneret/afiliimi",
    component: _71f822ea,
    name: "partners-affiliate___sq"
  }, {
    path: "/sq/partneret/agjencia-e-udhetimeve",
    component: _478e8254,
    name: "partners-travel-agency___sq"
  }, {
    path: "/sq/partneret/referoni-nje-mik",
    component: _5d18eebd,
    name: "partners-refer-friend___sq"
  }, {
    path: "/sq/partneret/studio-ligjore",
    component: _0dd264ad,
    name: "partners-lawyers___sq"
  }, {
    path: "/sq/partneret/terma-dhe-kushte-referimi-i-nje-miku",
    component: _5a4a8cc8,
    name: "partners-refer-friend-tos___sq"
  }, {
    path: "/sq/perdorues/afiluar",
    component: _32127aab,
    name: "user-affiliate___sq"
  }, {
    path: "/sq/perdorues/dil",
    component: _475e379c,
    name: "user-logout___sq"
  }, {
    path: "/sq/perdorues/fjale-kalimi",
    component: _3cb494a6,
    name: "user-password___sq"
  }, {
    path: "/sq/perdorues/profili",
    component: _4a858a87,
    name: "user-profile___sq"
  }, {
    path: "/sq/perdorues/pult",
    component: _1399e472,
    name: "user-dashboard___sq"
  }, {
    path: "/sq/perdorues/qasje",
    component: _e797ec72,
    name: "user-login___sq"
  }, {
    path: "/sq/perdorues/registrohu",
    component: _8cf556ee,
    name: "user-sign-up___sq"
  }, {
    path: "/bg/potrebitel/affiliate/iskane-za-plashtane",
    component: _501d677c,
    name: "user-affiliate_payment_request___bg"
  }, {
    path: "/bg/potrebitel/affiliate/istoriya-na-plashtaniyata",
    component: _af409872,
    name: "user-affiliate_payment_history___bg"
  }, {
    path: "/bg/potrebitel/affiliate/kreativnost",
    component: _577ba523,
    name: "user-affiliate_creative_material___bg"
  }, {
    path: "/bg/potrebitel/affiliate/metod-na-plashtane",
    component: _4cce3604,
    name: "user-affiliate_payment_method___bg"
  }, {
    path: "/bg/potrebitel/affiliate/prehod-kam-programa-za-afiliatsiya",
    component: _13a3b50d,
    name: "user-affiliate_convert_account___bg"
  }, {
    path: "/bg/potrebitel/affiliate/prisuedinete-se-kam-afiliaciyata",
    component: _d4de4592,
    name: "user-affiliate_activate___bg"
  }, {
    path: "/bg/potrebitel/affiliate/spisak-s-oplakvaniya",
    component: _57862220,
    name: "user-travel-agency-claims___bg"
  }, {
    path: "/bg/potrebitel/affiliate/uchastvai-preporachi-priyatel",
    component: _781fbfbe,
    name: "user-affiliate_refer_friend_activate___bg"
  }, {
    path: "/bg/potrebitel/turisticheska-agentsiya/formulyar-za-oplakvaniya-oflayn",
    component: _2ea536d2,
    name: "user-travel-agency-offline-form___bg"
  }, {
    path: "/bg/potrebitel/turisticheska-agentsiya/iskane-za-plashtane",
    component: _7806cfa2,
    name: "user-travel-agency-payment-request___bg"
  }, {
    path: "/bg/potrebitel/turisticheska-agentsiya/istoriya-na-plashtaniyata",
    component: _d72a0098,
    name: "user-travel-agency-payment-history___bg"
  }, {
    path: "/bg/potrebitel/turisticheska-agentsiya/kachvane-na-oflayn-pretenziya",
    component: _520ade2f,
    name: "user-travel-agency-upload-offline-claim___bg"
  }, {
    path: "/bg/potrebitel/turisticheska-agentsiya/komisii",
    component: _62207262,
    name: "user-travel-agency-commissions___bg"
  }, {
    path: "/bg/potrebitel/turisticheska-agentsiya/metod-na-plashtane",
    component: _7fa4311e,
    name: "user-travel-agency-payment-method___bg"
  }, {
    path: "/bg/potrebitel/turisticheska-agentsiya/offline",
    component: _1dfcbdca,
    name: "user-travel-agency-offline___bg"
  }, {
    path: "/bg/potrebitel/turisticheska-agentsiya/onlain",
    component: _6b1ccabc,
    name: "user-travel-agency-online___bg"
  }, {
    path: "/bg/potrebitel/turisticheska-agentsiya/onlayn-banner",
    component: _329a23b6,
    name: "user-travel-agency-online-banners___bg"
  }, {
    path: "/bg/user/travel-agency/new-claim",
    component: _b073e014,
    name: "user-travel-agency-new-claim___bg"
  }, {
    path: "/cs/user/travel-agency/new-claim",
    component: _b073e014,
    name: "user-travel-agency-new-claim___cs"
  }, {
    path: "/cs/uzivatel/affiliate/historie-plateb",
    component: _af409872,
    name: "user-affiliate_payment_history___cs"
  }, {
    path: "/cs/uzivatel/affiliate/kreativita",
    component: _577ba523,
    name: "user-affiliate_creative_material___cs"
  }, {
    path: "/cs/uzivatel/affiliate/platebni-metoda",
    component: _4cce3604,
    name: "user-affiliate_payment_method___cs"
  }, {
    path: "/cs/uzivatel/affiliate/prejdi-na-affiliation",
    component: _13a3b50d,
    name: "user-affiliate_convert_account___cs"
  }, {
    path: "/cs/uzivatel/affiliate/pripojit-se-doporuc-pritele",
    component: _781fbfbe,
    name: "user-affiliate_refer_friend_activate___cs"
  }, {
    path: "/cs/uzivatel/affiliate/pripojit-se-k-affiliacnimu-programu",
    component: _d4de4592,
    name: "user-affiliate_activate___cs"
  }, {
    path: "/cs/uzivatel/affiliate/seznam-reklamaci",
    component: _57862220,
    name: "user-travel-agency-claims___cs"
  }, {
    path: "/cs/uzivatel/affiliate/zadost-o-platbu",
    component: _501d677c,
    name: "user-affiliate_payment_request___cs"
  }, {
    path: "/cs/uzivatel/cestovni-kancelar/historie-plateb",
    component: _d72a0098,
    name: "user-travel-agency-payment-history___cs"
  }, {
    path: "/cs/uzivatel/cestovni-kancelar/nahrat-offline-reklamaci",
    component: _520ade2f,
    name: "user-travel-agency-upload-offline-claim___cs"
  }, {
    path: "/cs/uzivatel/cestovni-kancelar/offline",
    component: _1dfcbdca,
    name: "user-travel-agency-offline___cs"
  }, {
    path: "/cs/uzivatel/cestovni-kancelar/offline-reklamacni-formular",
    component: _2ea536d2,
    name: "user-travel-agency-offline-form___cs"
  }, {
    path: "/cs/uzivatel/cestovni-kancelar/online",
    component: _6b1ccabc,
    name: "user-travel-agency-online___cs"
  }, {
    path: "/cs/uzivatel/cestovni-kancelar/online-banner",
    component: _329a23b6,
    name: "user-travel-agency-online-banners___cs"
  }, {
    path: "/cs/uzivatel/cestovni-kancelar/platebni-metoda",
    component: _7fa4311e,
    name: "user-travel-agency-payment-method___cs"
  }, {
    path: "/cs/uzivatel/cestovni-kancelar/provizy",
    component: _62207262,
    name: "user-travel-agency-commissions___cs"
  }, {
    path: "/cs/uzivatel/cestovni-kancelar/zadost-o-platbu",
    component: _7806cfa2,
    name: "user-travel-agency-payment-request___cs"
  }, {
    path: "/de/benutzer/affiliate/am-affiliate-programm-teilnehmen",
    component: _d4de4592,
    name: "user-affiliate_activate___de"
  }, {
    path: "/de/benutzer/affiliate/beschwerdeliste",
    component: _57862220,
    name: "user-travel-agency-claims___de"
  }, {
    path: "/de/benutzer/affiliate/kreativitat",
    component: _577ba523,
    name: "user-affiliate_creative_material___de"
  }, {
    path: "/de/benutzer/affiliate/teilnahme-empfehlen-einen-freund",
    component: _781fbfbe,
    name: "user-affiliate_refer_friend_activate___de"
  }, {
    path: "/de/benutzer/affiliate/zahlungsanforderung",
    component: _501d677c,
    name: "user-affiliate_payment_request___de"
  }, {
    path: "/de/benutzer/affiliate/zahlungsart",
    component: _4cce3604,
    name: "user-affiliate_payment_method___de"
  }, {
    path: "/de/benutzer/affiliate/zahlungsverlauf",
    component: _af409872,
    name: "user-affiliate_payment_history___de"
  }, {
    path: "/de/benutzer/partner/wechsel-zu-affiliation",
    component: _13a3b50d,
    name: "user-affiliate_convert_account___de"
  }, {
    path: "/de/benutzer/reisebuero/offline",
    component: _1dfcbdca,
    name: "user-travel-agency-offline___de"
  }, {
    path: "/de/benutzer/reisebuero/offline-beschwerde-hochladen",
    component: _520ade2f,
    name: "user-travel-agency-upload-offline-claim___de"
  }, {
    path: "/de/benutzer/reisebuero/offline-beschwerdeformular",
    component: _2ea536d2,
    name: "user-travel-agency-offline-form___de"
  }, {
    path: "/de/benutzer/reisebuero/online",
    component: _6b1ccabc,
    name: "user-travel-agency-online___de"
  }, {
    path: "/de/benutzer/reisebuero/online-banner",
    component: _329a23b6,
    name: "user-travel-agency-online-banners___de"
  }, {
    path: "/de/benutzer/reisebuero/provisionen",
    component: _62207262,
    name: "user-travel-agency-commissions___de"
  }, {
    path: "/de/benutzer/reisebuero/zahlungsanforderung",
    component: _7806cfa2,
    name: "user-travel-agency-payment-request___de"
  }, {
    path: "/de/benutzer/reisebuero/zahlungsart",
    component: _7fa4311e,
    name: "user-travel-agency-payment-method___de"
  }, {
    path: "/de/benutzer/reisebuero/zahlungsverlauf",
    component: _d72a0098,
    name: "user-travel-agency-payment-history___de"
  }, {
    path: "/de/user/travel-agency/new-claim",
    component: _b073e014,
    name: "user-travel-agency-new-claim___de"
  }, {
    path: "/en/user/affiliate/claim-list",
    component: _57862220,
    name: "user-travel-agency-claims___en"
  }, {
    path: "/en/user/affiliate/convert-account-to-affiliation",
    component: _13a3b50d,
    name: "user-affiliate_convert_account___en"
  }, {
    path: "/en/user/affiliate/creativity",
    component: _577ba523,
    name: "user-affiliate_creative_material___en"
  }, {
    path: "/en/user/affiliate/join-affiliation-program",
    component: _d4de4592,
    name: "user-affiliate_activate___en"
  }, {
    path: "/en/user/affiliate/join-refer-a-friend",
    component: _781fbfbe,
    name: "user-affiliate_refer_friend_activate___en"
  }, {
    path: "/en/user/affiliate/payment-history",
    component: _af409872,
    name: "user-affiliate_payment_history___en"
  }, {
    path: "/en/user/affiliate/payment-method",
    component: _4cce3604,
    name: "user-affiliate_payment_method___en"
  }, {
    path: "/en/user/affiliate/payment-request",
    component: _501d677c,
    name: "user-affiliate_payment_request___en"
  }, {
    path: "/en/user/travel-agency/commissions",
    component: _62207262,
    name: "user-travel-agency-commissions___en"
  }, {
    path: "/en/user/travel-agency/new-claim",
    component: _b073e014,
    name: "user-travel-agency-new-claim___en"
  }, {
    path: "/en/user/travel-agency/offline",
    component: _1dfcbdca,
    name: "user-travel-agency-offline___en"
  }, {
    path: "/en/user/travel-agency/offline-claim-form",
    component: _2ea536d2,
    name: "user-travel-agency-offline-form___en"
  }, {
    path: "/en/user/travel-agency/online",
    component: _6b1ccabc,
    name: "user-travel-agency-online___en"
  }, {
    path: "/en/user/travel-agency/online-banner",
    component: _329a23b6,
    name: "user-travel-agency-online-banners___en"
  }, {
    path: "/en/user/travel-agency/payment-history",
    component: _d72a0098,
    name: "user-travel-agency-payment-history___en"
  }, {
    path: "/en/user/travel-agency/payment-method",
    component: _7fa4311e,
    name: "user-travel-agency-payment-method___en"
  }, {
    path: "/en/user/travel-agency/payment-request",
    component: _7806cfa2,
    name: "user-travel-agency-payment-request___en"
  }, {
    path: "/en/user/travel-agency/upload-offline-claim",
    component: _520ade2f,
    name: "user-travel-agency-upload-offline-claim___en"
  }, {
    path: "/es/user/travel-agency/new-claim",
    component: _b073e014,
    name: "user-travel-agency-new-claim___es"
  }, {
    path: "/es/usuario/afiliado/cambio-a-afiliacion",
    component: _13a3b50d,
    name: "user-affiliate_convert_account___es"
  }, {
    path: "/es/usuario/afiliado/creatividad",
    component: _577ba523,
    name: "user-affiliate_creative_material___es"
  }, {
    path: "/es/usuario/afiliado/historial-de-pagos",
    component: _af409872,
    name: "user-affiliate_payment_history___es"
  }, {
    path: "/es/usuario/afiliado/lista-de-reclamos",
    component: _57862220,
    name: "user-travel-agency-claims___es"
  }, {
    path: "/es/usuario/afiliado/metodo-pago",
    component: _4cce3604,
    name: "user-affiliate_payment_method___es"
  }, {
    path: "/es/usuario/afiliado/participa-en-recomienda-a-un-amigo",
    component: _781fbfbe,
    name: "user-affiliate_refer_friend_activate___es"
  }, {
    path: "/es/usuario/afiliado/solicitud-pago",
    component: _501d677c,
    name: "user-affiliate_payment_request___es"
  }, {
    path: "/es/usuario/afiliado/unete-al-programa-de-afiliados",
    component: _d4de4592,
    name: "user-affiliate_activate___es"
  }, {
    path: "/es/usuario/agencia-de-viajes/banner-en-linea",
    component: _329a23b6,
    name: "user-travel-agency-online-banners___es"
  }, {
    path: "/es/usuario/agencia-de-viajes/comisiones",
    component: _62207262,
    name: "user-travel-agency-commissions___es"
  }, {
    path: "/es/usuario/agencia-de-viajes/en-linea",
    component: _6b1ccabc,
    name: "user-travel-agency-online___es"
  }, {
    path: "/es/usuario/agencia-de-viajes/formulario-reclamo-desconectado",
    component: _2ea536d2,
    name: "user-travel-agency-offline-form___es"
  }, {
    path: "/es/usuario/agencia-de-viajes/historial-de-pagos",
    component: _d72a0098,
    name: "user-travel-agency-payment-history___es"
  }, {
    path: "/es/usuario/agencia-de-viajes/metodo-pago",
    component: _7fa4311e,
    name: "user-travel-agency-payment-method___es"
  }, {
    path: "/es/usuario/agencia-de-viajes/offline",
    component: _1dfcbdca,
    name: "user-travel-agency-offline___es"
  }, {
    path: "/es/usuario/agencia-de-viajes/solicitud-pago",
    component: _7806cfa2,
    name: "user-travel-agency-payment-request___es"
  }, {
    path: "/es/usuario/agencia-de-viajes/subir-reclamacion-offline",
    component: _520ade2f,
    name: "user-travel-agency-upload-offline-claim___es"
  }, {
    path: "/hu/felhasznalo/affiliate/csatlakozas-ajanld-egy-baratot",
    component: _781fbfbe,
    name: "user-affiliate_refer_friend_activate___hu"
  }, {
    path: "/hu/felhasznalo/affiliate/csatlakozas-az-afilliacios-programhoz",
    component: _d4de4592,
    name: "user-affiliate_activate___hu"
  }, {
    path: "/hu/felhasznalo/affiliate/fizetesi-elozmenyek",
    component: _af409872,
    name: "user-affiliate_payment_history___hu"
  }, {
    path: "/hu/felhasznalo/affiliate/fizetesi-kerelem",
    component: _501d677c,
    name: "user-affiliate_payment_request___hu"
  }, {
    path: "/hu/felhasznalo/affiliate/fizetesi-mod",
    component: _4cce3604,
    name: "user-affiliate_payment_method___hu"
  }, {
    path: "/hu/felhasznalo/affiliate/kreativitas",
    component: _577ba523,
    name: "user-affiliate_creative_material___hu"
  }, {
    path: "/hu/felhasznalo/affiliate/panasz-lista",
    component: _57862220,
    name: "user-travel-agency-claims___hu"
  }, {
    path: "/hu/felhasznalo/affiliate/ugyfel-valtas-affiliaciora",
    component: _13a3b50d,
    name: "user-affiliate_convert_account___hu"
  }, {
    path: "/hu/felhasznalo/utazasi-iroda/felvetel-offline-panasz",
    component: _520ade2f,
    name: "user-travel-agency-upload-offline-claim___hu"
  }, {
    path: "/hu/felhasznalo/utazasi-iroda/fizetesi-elozmenyek",
    component: _d72a0098,
    name: "user-travel-agency-payment-history___hu"
  }, {
    path: "/hu/felhasznalo/utazasi-iroda/fizetesi-keres",
    component: _7806cfa2,
    name: "user-travel-agency-payment-request___hu"
  }, {
    path: "/hu/felhasznalo/utazasi-iroda/fizetesi-mod",
    component: _7fa4311e,
    name: "user-travel-agency-payment-method___hu"
  }, {
    path: "/hu/felhasznalo/utazasi-iroda/jutalekok",
    component: _62207262,
    name: "user-travel-agency-commissions___hu"
  }, {
    path: "/hu/felhasznalo/utazasi-iroda/offline",
    component: _1dfcbdca,
    name: "user-travel-agency-offline___hu"
  }, {
    path: "/hu/felhasznalo/utazasi-iroda/offline-panaszlap",
    component: _2ea536d2,
    name: "user-travel-agency-offline-form___hu"
  }, {
    path: "/hu/felhasznalo/utazasi-iroda/online",
    component: _6b1ccabc,
    name: "user-travel-agency-online___hu"
  }, {
    path: "/hu/felhasznalo/utazasi-iroda/online-banner",
    component: _329a23b6,
    name: "user-travel-agency-online-banners___hu"
  }, {
    path: "/hu/user/travel-agency/new-claim",
    component: _b073e014,
    name: "user-travel-agency-new-claim___hu"
  }, {
    path: "/it/user/travel-agency/new-claim",
    component: _b073e014,
    name: "user-travel-agency-new-claim___it"
  }, {
    path: "/it/utente/affiliato/creativita",
    component: _577ba523,
    name: "user-affiliate_creative_material___it"
  }, {
    path: "/it/utente/affiliato/lista-reclami",
    component: _57862220,
    name: "user-travel-agency-claims___it"
  }, {
    path: "/it/utente/affiliato/metodo-pagamento",
    component: _4cce3604,
    name: "user-affiliate_payment_method___it"
  }, {
    path: "/it/utente/affiliato/partecipa-programma-affiliazione",
    component: _d4de4592,
    name: "user-affiliate_activate___it"
  }, {
    path: "/it/utente/affiliato/partecipa-segnala-un-amico",
    component: _781fbfbe,
    name: "user-affiliate_refer_friend_activate___it"
  }, {
    path: "/it/utente/affiliato/passa-ad-affiliazione",
    component: _13a3b50d,
    name: "user-affiliate_convert_account___it"
  }, {
    path: "/it/utente/affiliato/richiesta-pagamento",
    component: _501d677c,
    name: "user-affiliate_payment_request___it"
  }, {
    path: "/it/utente/affiliato/storico-pagamenti",
    component: _af409872,
    name: "user-affiliate_payment_history___it"
  }, {
    path: "/it/utente/agenzia-viaggio/banner-online",
    component: _329a23b6,
    name: "user-travel-agency-online-banners___it"
  }, {
    path: "/it/utente/agenzia-viaggio/carica-reclamo-offline",
    component: _520ade2f,
    name: "user-travel-agency-upload-offline-claim___it"
  }, {
    path: "/it/utente/agenzia-viaggio/cronologia-pagamenti",
    component: _d72a0098,
    name: "user-travel-agency-payment-history___it"
  }, {
    path: "/it/utente/agenzia-viaggio/form-reclamo-offline",
    component: _2ea536d2,
    name: "user-travel-agency-offline-form___it"
  }, {
    path: "/it/utente/agenzia-viaggio/metodo-pagamento",
    component: _7fa4311e,
    name: "user-travel-agency-payment-method___it"
  }, {
    path: "/it/utente/agenzia-viaggio/offline",
    component: _1dfcbdca,
    name: "user-travel-agency-offline___it"
  }, {
    path: "/it/utente/agenzia-viaggio/online",
    component: _6b1ccabc,
    name: "user-travel-agency-online___it"
  }, {
    path: "/it/utente/agenzia-viaggio/provvigioni",
    component: _62207262,
    name: "user-travel-agency-commissions___it"
  }, {
    path: "/it/utente/agenzia-viaggio/richiesta-pagamento",
    component: _7806cfa2,
    name: "user-travel-agency-payment-request___it"
  }, {
    path: "/pl/user/travel-agency/new-claim",
    component: _b073e014,
    name: "user-travel-agency-new-claim___pl"
  }, {
    path: "/pl/uzytkownik/affiliate/dolacz-do-programu-afiliacyjnego",
    component: _d4de4592,
    name: "user-affiliate_activate___pl"
  }, {
    path: "/pl/uzytkownik/affiliate/dolacz-polec-znajomego",
    component: _781fbfbe,
    name: "user-affiliate_refer_friend_activate___pl"
  }, {
    path: "/pl/uzytkownik/affiliate/historia-platnosci",
    component: _af409872,
    name: "user-affiliate_payment_history___pl"
  }, {
    path: "/pl/uzytkownik/affiliate/kreatywnosc",
    component: _577ba523,
    name: "user-affiliate_creative_material___pl"
  }, {
    path: "/pl/uzytkownik/affiliate/lista-roszczen",
    component: _57862220,
    name: "user-travel-agency-claims___pl"
  }, {
    path: "/pl/uzytkownik/affiliate/sposob-platnosci",
    component: _4cce3604,
    name: "user-affiliate_payment_method___pl"
  }, {
    path: "/pl/uzytkownik/affiliate/wniosek-o-platnosc",
    component: _501d677c,
    name: "user-affiliate_payment_request___pl"
  }, {
    path: "/pl/uzytkownik/affiliate/zamien-konto-na-afiliacje",
    component: _13a3b50d,
    name: "user-affiliate_convert_account___pl"
  }, {
    path: "/pl/uzytkownik/agencja-podrozy/formularz-reklamacji-offline",
    component: _2ea536d2,
    name: "user-travel-agency-offline-form___pl"
  }, {
    path: "/pl/uzytkownik/agencja-podrozy/historia-platnosci",
    component: _d72a0098,
    name: "user-travel-agency-payment-history___pl"
  }, {
    path: "/pl/uzytkownik/agencja-podrozy/metoda-platnosci",
    component: _7fa4311e,
    name: "user-travel-agency-payment-method___pl"
  }, {
    path: "/pl/uzytkownik/agencja-podrozy/offline",
    component: _1dfcbdca,
    name: "user-travel-agency-offline___pl"
  }, {
    path: "/pl/uzytkownik/agencja-podrozy/online",
    component: _6b1ccabc,
    name: "user-travel-agency-online___pl"
  }, {
    path: "/pl/uzytkownik/agencja-podrozy/online-banner",
    component: _329a23b6,
    name: "user-travel-agency-online-banners___pl"
  }, {
    path: "/pl/uzytkownik/agencja-podrozy/prowizje",
    component: _62207262,
    name: "user-travel-agency-commissions___pl"
  }, {
    path: "/pl/uzytkownik/agencja-podrozy/przeslij-reklamacje-offline",
    component: _520ade2f,
    name: "user-travel-agency-upload-offline-claim___pl"
  }, {
    path: "/pl/uzytkownik/agencja-podrozy/wniosek-o-platnosc",
    component: _7806cfa2,
    name: "user-travel-agency-payment-request___pl"
  }, {
    path: "/pt/user/travel-agency/new-claim",
    component: _b073e014,
    name: "user-travel-agency-new-claim___pt"
  }, {
    path: "/pt/usuario/afiliado/alterar-para-afiliacao",
    component: _13a3b50d,
    name: "user-affiliate_convert_account___pt"
  }, {
    path: "/pt/usuario/afiliado/criatividade",
    component: _577ba523,
    name: "user-affiliate_creative_material___pt"
  }, {
    path: "/pt/usuario/afiliado/historico-pagamentos",
    component: _af409872,
    name: "user-affiliate_payment_history___pt"
  }, {
    path: "/pt/usuario/afiliado/lista-de-reclamacoes",
    component: _57862220,
    name: "user-travel-agency-claims___pt"
  }, {
    path: "/pt/usuario/afiliado/metodo-pagamento",
    component: _4cce3604,
    name: "user-affiliate_payment_method___pt"
  }, {
    path: "/pt/usuario/afiliado/participa-indica-um-amigo",
    component: _781fbfbe,
    name: "user-affiliate_refer_friend_activate___pt"
  }, {
    path: "/pt/usuario/afiliado/participa-programa-afiliacao",
    component: _d4de4592,
    name: "user-affiliate_activate___pt"
  }, {
    path: "/pt/usuario/afiliado/pedido-pagamento",
    component: _501d677c,
    name: "user-affiliate_payment_request___pt"
  }, {
    path: "/pt/usuario/agencia-de-viagens/banner-online",
    component: _329a23b6,
    name: "user-travel-agency-online-banners___pt"
  }, {
    path: "/pt/usuario/agencia-de-viagens/comissoes",
    component: _62207262,
    name: "user-travel-agency-commissions___pt"
  }, {
    path: "/pt/usuario/agencia-de-viagens/enviar-reclamacao-offline",
    component: _520ade2f,
    name: "user-travel-agency-upload-offline-claim___pt"
  }, {
    path: "/pt/usuario/agencia-de-viagens/formulario-reclamacao-offline",
    component: _2ea536d2,
    name: "user-travel-agency-offline-form___pt"
  }, {
    path: "/pt/usuario/agencia-de-viagens/historico-pagamentos",
    component: _d72a0098,
    name: "user-travel-agency-payment-history___pt"
  }, {
    path: "/pt/usuario/agencia-de-viagens/metodo-pagamento",
    component: _7fa4311e,
    name: "user-travel-agency-payment-method___pt"
  }, {
    path: "/pt/usuario/agencia-de-viagens/offline",
    component: _1dfcbdca,
    name: "user-travel-agency-offline___pt"
  }, {
    path: "/pt/usuario/agencia-de-viagens/online",
    component: _6b1ccabc,
    name: "user-travel-agency-online___pt"
  }, {
    path: "/pt/usuario/agencia-de-viagens/solicitacao-pagamento",
    component: _7806cfa2,
    name: "user-travel-agency-payment-request___pt"
  }, {
    path: "/ro/user/affiliate/cerere-plata",
    component: _501d677c,
    name: "user-affiliate_payment_request___ro"
  }, {
    path: "/ro/user/affiliate/creativitate",
    component: _577ba523,
    name: "user-affiliate_creative_material___ro"
  }, {
    path: "/ro/user/affiliate/lista-de-reclamatii",
    component: _57862220,
    name: "user-travel-agency-claims___ro"
  }, {
    path: "/ro/user/affiliate/metoda-de-plata",
    component: _4cce3604,
    name: "user-affiliate_payment_method___ro"
  }, {
    path: "/ro/user/affiliate/participare-program-afiliere",
    component: _d4de4592,
    name: "user-affiliate_activate___ro"
  }, {
    path: "/ro/user/affiliate/participare-recomanda-un-prieten",
    component: _781fbfbe,
    name: "user-affiliate_refer_friend_activate___ro"
  }, {
    path: "/ro/user/affiliate/payment-history",
    component: _af409872,
    name: "user-affiliate_payment_history___ro"
  }, {
    path: "/ro/user/affiliate/trecere-la-programul-de-afiliere",
    component: _13a3b50d,
    name: "user-affiliate_convert_account___ro"
  }, {
    path: "/ro/user/travel-agency/new-claim",
    component: _b073e014,
    name: "user-travel-agency-new-claim___ro"
  }, {
    path: "/ro/utilizator/agentie-de-calatorie/banner-online",
    component: _329a23b6,
    name: "user-travel-agency-online-banners___ro"
  }, {
    path: "/ro/utilizator/agentie-de-calatorie/cerere-plata",
    component: _7806cfa2,
    name: "user-travel-agency-payment-request___ro"
  }, {
    path: "/ro/utilizator/agentie-de-calatorie/comisioane",
    component: _62207262,
    name: "user-travel-agency-commissions___ro"
  }, {
    path: "/ro/utilizator/agentie-de-calatorie/formular-reclamatie-offline",
    component: _2ea536d2,
    name: "user-travel-agency-offline-form___ro"
  }, {
    path: "/ro/utilizator/agentie-de-calatorie/incarca-reclamatie-offline",
    component: _520ade2f,
    name: "user-travel-agency-upload-offline-claim___ro"
  }, {
    path: "/ro/utilizator/agentie-de-calatorie/istoric-plati",
    component: _d72a0098,
    name: "user-travel-agency-payment-history___ro"
  }, {
    path: "/ro/utilizator/agentie-de-calatorie/metoda-de-plata",
    component: _7fa4311e,
    name: "user-travel-agency-payment-method___ro"
  }, {
    path: "/ro/utilizator/agentie-de-calatorie/offline",
    component: _1dfcbdca,
    name: "user-travel-agency-offline___ro"
  }, {
    path: "/ro/utilizator/agentie-de-calatorie/online",
    component: _6b1ccabc,
    name: "user-travel-agency-online___ro"
  }, {
    path: "/ru/polzovatel/affiliate/spisok-zhalob",
    component: _57862220,
    name: "user-travel-agency-claims___ru"
  }, {
    path: "/ru/polzovatel/turisticheskoe-agentstvo/forma-zhaloby-vne-seti",
    component: _2ea536d2,
    name: "user-travel-agency-offline-form___ru"
  }, {
    path: "/ru/polzovatel/turisticheskoe-agentstvo/istoriya-platezhey",
    component: _d72a0098,
    name: "user-travel-agency-payment-history___ru"
  }, {
    path: "/ru/polzovatel/turisticheskoe-agentstvo/komissii",
    component: _62207262,
    name: "user-travel-agency-commissions___ru"
  }, {
    path: "/ru/polzovatel/turisticheskoe-agentstvo/metod-oplaty",
    component: _7fa4311e,
    name: "user-travel-agency-payment-method___ru"
  }, {
    path: "/ru/polzovatel/turisticheskoe-agentstvo/offline",
    component: _1dfcbdca,
    name: "user-travel-agency-offline___ru"
  }, {
    path: "/ru/polzovatel/turisticheskoe-agentstvo/onlain",
    component: _6b1ccabc,
    name: "user-travel-agency-online___ru"
  }, {
    path: "/ru/polzovatel/turisticheskoe-agentstvo/onlayn-banner",
    component: _329a23b6,
    name: "user-travel-agency-online-banners___ru"
  }, {
    path: "/ru/polzovatel/turisticheskoe-agentstvo/zagruzit-offlayn-pretenziyu",
    component: _520ade2f,
    name: "user-travel-agency-upload-offline-claim___ru"
  }, {
    path: "/ru/polzovatel/turisticheskoe-agentstvo/zapros-na-oplatu",
    component: _7806cfa2,
    name: "user-travel-agency-payment-request___ru"
  }, {
    path: "/ru/user/affiliate/creativity",
    component: _577ba523,
    name: "user-affiliate_creative_material___ru"
  }, {
    path: "/ru/user/affiliate/payment-history",
    component: _af409872,
    name: "user-affiliate_payment_history___ru"
  }, {
    path: "/ru/user/affiliate/payment-method",
    component: _4cce3604,
    name: "user-affiliate_payment_method___ru"
  }, {
    path: "/ru/user/affiliate/payment-request",
    component: _501d677c,
    name: "user-affiliate_payment_request___ru"
  }, {
    path: "/ru/user/affiliate/propusk-v-programmu-affilirovannosti",
    component: _d4de4592,
    name: "user-affiliate_activate___ru"
  }, {
    path: "/ru/user/affiliate/propusk-v-programmu-affilirovannosti",
    component: _13a3b50d,
    name: "user-affiliate_convert_account___ru"
  }, {
    path: "/ru/user/affiliate/propusk-v-programmu-xx",
    component: _781fbfbe,
    name: "user-affiliate_refer_friend_activate___ru"
  }, {
    path: "/ru/user/travel-agency/new-claim",
    component: _b073e014,
    name: "user-travel-agency-new-claim___ru"
  }, {
    path: "/sq/perdorues/affiliate/bashkohu-programit-te-afiliuar",
    component: _d4de4592,
    name: "user-affiliate_activate___sq"
  }, {
    path: "/sq/perdorues/affiliate/bashkohu-rekomando-nje-mik",
    component: _781fbfbe,
    name: "user-affiliate_refer_friend_activate___sq"
  }, {
    path: "/sq/perdorues/affiliate/historia-e-pagesave",
    component: _af409872,
    name: "user-affiliate_payment_history___sq"
  }, {
    path: "/sq/perdorues/affiliate/kalo-ne-afiliim",
    component: _13a3b50d,
    name: "user-affiliate_convert_account___sq"
  }, {
    path: "/sq/perdorues/affiliate/kerkese-pagesa",
    component: _501d677c,
    name: "user-affiliate_payment_request___sq"
  }, {
    path: "/sq/perdorues/affiliate/kreativitet",
    component: _577ba523,
    name: "user-affiliate_creative_material___sq"
  }, {
    path: "/sq/perdorues/affiliate/lista-e-ankesave",
    component: _57862220,
    name: "user-travel-agency-claims___sq"
  }, {
    path: "/sq/perdorues/affiliate/metoda-e-pageses",
    component: _4cce3604,
    name: "user-affiliate_payment_method___sq"
  }, {
    path: "/sq/perdorues/agjencia-e-udhetimeve/formulari-i-ankeses-offline",
    component: _2ea536d2,
    name: "user-travel-agency-offline-form___sq"
  }, {
    path: "/sq/perdorues/agjencia-e-udhetimeve/komisionet",
    component: _62207262,
    name: "user-travel-agency-commissions___sq"
  }, {
    path: "/sq/perdorues/agjencia-e-udhetimeve/offline",
    component: _1dfcbdca,
    name: "user-travel-agency-offline___sq"
  }, {
    path: "/sq/perdorues/agjencia-udhetimesh/banner-online",
    component: _329a23b6,
    name: "user-travel-agency-online-banners___sq"
  }, {
    path: "/sq/perdorues/agjencia-udhetimesh/historia-e-pagesave",
    component: _d72a0098,
    name: "user-travel-agency-payment-history___sq"
  }, {
    path: "/sq/perdorues/agjencia-udhetimesh/kerkese-pagesa",
    component: _7806cfa2,
    name: "user-travel-agency-payment-request___sq"
  }, {
    path: "/sq/perdorues/agjencia-udhetimesh/metoda-e-pageses",
    component: _7fa4311e,
    name: "user-travel-agency-payment-method___sq"
  }, {
    path: "/sq/perdorues/agjencia-udhetimesh/ngarko-ankesen-offline",
    component: _520ade2f,
    name: "user-travel-agency-upload-offline-claim___sq"
  }, {
    path: "/sq/perdorues/agjencia-udhetimesh/online",
    component: _6b1ccabc,
    name: "user-travel-agency-online___sq"
  }, {
    path: "/sq/user/travel-agency/new-claim",
    component: _b073e014,
    name: "user-travel-agency-new-claim___sq"
  }, {
    path: "/bg/potrebitel/turisticheska-agentsiya/promeni-zhalbata/:code",
    component: _38d4d19f,
    name: "user-travel-agency-edit-claim-code___bg"
  }, {
    path: "/bg/potrebitel/turisticheska-agentsiya/status-na-reklamatsiya/:code",
    component: _3e662347,
    name: "user-travel-agency-status-claim-code___bg"
  }, {
    path: "/cs/uzivatel/cestovni-kancelar/stav-reklamace/:code",
    component: _3e662347,
    name: "user-travel-agency-status-claim-code___cs"
  }, {
    path: "/cs/uzivatel/cestovni-kancelar/upravit-reklamaci/:code",
    component: _38d4d19f,
    name: "user-travel-agency-edit-claim-code___cs"
  }, {
    path: "/de/benutzer/reisebuero/beschwerde-bearbeiten/:code",
    component: _38d4d19f,
    name: "user-travel-agency-edit-claim-code___de"
  }, {
    path: "/de/benutzer/reisebuero/status-beschwerde/:code",
    component: _3e662347,
    name: "user-travel-agency-status-claim-code___de"
  }, {
    path: "/en/user/travel-agency/edit-claim/:code",
    component: _38d4d19f,
    name: "user-travel-agency-edit-claim-code___en"
  }, {
    path: "/en/user/travel-agency/status-claim/:code",
    component: _3e662347,
    name: "user-travel-agency-status-claim-code___en"
  }, {
    path: "/es/usuario/agencia-de-viajes/estado-reclamo/:code",
    component: _3e662347,
    name: "user-travel-agency-status-claim-code___es"
  }, {
    path: "/es/usuario/agencia-de-viajes/modificar-reclamo/:code",
    component: _38d4d19f,
    name: "user-travel-agency-edit-claim-code___es"
  }, {
    path: "/hu/felhasznalo/utazasi-iroda/reklamacio-allapot/:code",
    component: _3e662347,
    name: "user-travel-agency-status-claim-code___hu"
  }, {
    path: "/hu/felhasznalo/utazasi-iroda/reklamacio-szerkesztes/:code",
    component: _38d4d19f,
    name: "user-travel-agency-edit-claim-code___hu"
  }, {
    path: "/it/utente/agenzia-viaggio/modifica-reclamo/:code",
    component: _38d4d19f,
    name: "user-travel-agency-edit-claim-code___it"
  }, {
    path: "/it/utente/agenzia-viaggio/status-reclamo/:code",
    component: _3e662347,
    name: "user-travel-agency-status-claim-code___it"
  }, {
    path: "/pl/uzytkownik/agencja-podrozy/edytuj-reklamacje/:code",
    component: _38d4d19f,
    name: "user-travel-agency-edit-claim-code___pl"
  }, {
    path: "/pl/uzytkownik/agencja-podrozy/status-reklamacji/:code",
    component: _3e662347,
    name: "user-travel-agency-status-claim-code___pl"
  }, {
    path: "/pt/usuario/agencia-de-viagens/editar-reclamacao/:code",
    component: _38d4d19f,
    name: "user-travel-agency-edit-claim-code___pt"
  }, {
    path: "/pt/usuario/agencia-de-viagens/status-reclamacao/:code",
    component: _3e662347,
    name: "user-travel-agency-status-claim-code___pt"
  }, {
    path: "/ro/utilizator/agentie-de-calatorie/reclamatie-modificare/:code",
    component: _38d4d19f,
    name: "user-travel-agency-edit-claim-code___ro"
  }, {
    path: "/ro/utilizator/agentie-de-calatorie/reclamatie-stare/:code",
    component: _3e662347,
    name: "user-travel-agency-status-claim-code___ro"
  }, {
    path: "/ru/polzovatel/turisticheskoe-agentstvo/izmenit-pretentsiya/:code",
    component: _38d4d19f,
    name: "user-travel-agency-edit-claim-code___ru"
  }, {
    path: "/ru/polzovatel/turisticheskoe-agentstvo/status-pretentsiya/:code",
    component: _3e662347,
    name: "user-travel-agency-status-claim-code___ru"
  }, {
    path: "/sq/perdorues/agjencia-e-udhetimeve/ndrysho-ankesen/:code",
    component: _38d4d19f,
    name: "user-travel-agency-edit-claim-code___sq"
  }, {
    path: "/sq/perdorues/agjencia-udhetimesh/statusi-i-ankeses/:code",
    component: _3e662347,
    name: "user-travel-agency-status-claim-code___sq"
  }, {
    path: "/bg/potrebitel/affiliate/zavarshvane-na-registraciyata/:code/:code2",
    component: _553e399b,
    name: "user-affiliate_complete_sign_up___bg"
  }, {
    path: "/cs/uzivatel/affiliate/dokonceni-registrace/:code/:code2",
    component: _553e399b,
    name: "user-affiliate_complete_sign_up___cs"
  }, {
    path: "/hu/felhasznalo/affiliate/regisztracio-befejezese/:code/:code2",
    component: _553e399b,
    name: "user-affiliate_complete_sign_up___hu"
  }, {
    path: "/pl/uzytkownik/affiliate/zakoncz-rejestracje/:code/:code2",
    component: _553e399b,
    name: "user-affiliate_complete_sign_up___pl"
  }, {
    path: "/sq/perdorues/affiliate/perfundimi-i-regjistrimit/:code/:code2",
    component: _553e399b,
    name: "user-affiliate_complete_sign_up___sq"
  }, {
    path: "/bg/potrebitel/potvardi-email/:code/:code2",
    component: _3b58408a,
    name: "user-confirm_email___bg"
  }, {
    path: "/bg/potrebitel/vazstanovyavane-na-parola/:code/:code2",
    component: _232f771d,
    name: "user-reset_password___bg"
  }, {
    path: "/bg/potrebitel/zavarshi-registratsiyata/:code/:code2",
    component: _1a51b329,
    name: "user-complete-sign-up___bg"
  }, {
    path: "/cs/uzivatel/dokonceni-registrace/:code/:code2",
    component: _1a51b329,
    name: "user-complete-sign-up___cs"
  }, {
    path: "/cs/uzivatel/obnovit-heslo/:code/:code2",
    component: _232f771d,
    name: "user-reset_password___cs"
  }, {
    path: "/cs/uzivatel/potvrzeni-emailu/:code/:code2",
    component: _3b58408a,
    name: "user-confirm_email___cs"
  }, {
    path: "/de/benutzer/bestaetige-email/:code/:code2",
    component: _3b58408a,
    name: "user-confirm_email___de"
  }, {
    path: "/de/benutzer/partner-registrierung-abschliessen/:code/:code2",
    component: _553e399b,
    name: "user-affiliate_complete_sign_up___de"
  }, {
    path: "/de/benutzer/passwort-zurucksetzen/:code/:code2",
    component: _232f771d,
    name: "user-reset_password___de"
  }, {
    path: "/de/benutzer/registrierung-abschliessen/:code/:code2",
    component: _1a51b329,
    name: "user-complete-sign-up___de"
  }, {
    path: "/en/user/affiliate-complete-registration/:code/:code2",
    component: _553e399b,
    name: "user-affiliate_complete_sign_up___en"
  }, {
    path: "/en/user/complete-registration/:code/:code2",
    component: _1a51b329,
    name: "user-complete-sign-up___en"
  }, {
    path: "/en/user/confirm-email/:code/:code2",
    component: _3b58408a,
    name: "user-confirm_email___en"
  }, {
    path: "/en/user/reset-password/:code/:code2",
    component: _232f771d,
    name: "user-reset_password___en"
  }, {
    path: "/es/usuario/afiliado-completar-registro/:code/:code2",
    component: _553e399b,
    name: "user-affiliate_complete_sign_up___es"
  }, {
    path: "/es/usuario/completar-registro/:code/:code2",
    component: _1a51b329,
    name: "user-complete-sign-up___es"
  }, {
    path: "/es/usuario/confirmar-email/:code/:code2",
    component: _3b58408a,
    name: "user-confirm_email___es"
  }, {
    path: "/es/usuario/restablecer-contrasena/:code/:code2",
    component: _232f771d,
    name: "user-reset_password___es"
  }, {
    path: "/hu/felhasznalo/jelszo-visszaallitas/:code/:code2",
    component: _232f771d,
    name: "user-reset_password___hu"
  }, {
    path: "/hu/felhasznalo/megerosites-email/:code/:code2",
    component: _3b58408a,
    name: "user-confirm_email___hu"
  }, {
    path: "/hu/felhasznalo/regisztracio-befejezese/:code/:code2",
    component: _1a51b329,
    name: "user-complete-sign-up___hu"
  }, {
    path: "/it/utente/affiliato-completa-registrazione/:code/:code2",
    component: _553e399b,
    name: "user-affiliate_complete_sign_up___it"
  }, {
    path: "/it/utente/completa-registrazione/:code/:code2",
    component: _1a51b329,
    name: "user-complete-sign-up___it"
  }, {
    path: "/it/utente/conferma-email/:code/:code2",
    component: _3b58408a,
    name: "user-confirm_email___it"
  }, {
    path: "/it/utente/resettare-password/:code/:code2",
    component: _232f771d,
    name: "user-reset_password___it"
  }, {
    path: "/pl/uzytkownik/potwierdz-email/:code/:code2",
    component: _3b58408a,
    name: "user-confirm_email___pl"
  }, {
    path: "/pl/uzytkownik/zakoncz-rejestracje/:code/:code2",
    component: _1a51b329,
    name: "user-complete-sign-up___pl"
  }, {
    path: "/pl/uzytkownik/zresetuj-haslo/:code/:code2",
    component: _232f771d,
    name: "user-reset_password___pl"
  }, {
    path: "/pt/usuario/afiliado-concluir-registro/:code/:code2",
    component: _553e399b,
    name: "user-affiliate_complete_sign_up___pt"
  }, {
    path: "/pt/usuario/concluir-registro/:code/:code2",
    component: _1a51b329,
    name: "user-complete-sign-up___pt"
  }, {
    path: "/pt/usuario/confirmar-email/:code/:code2",
    component: _3b58408a,
    name: "user-confirm_email___pt"
  }, {
    path: "/pt/usuario/redefinir-senha/:code/:code2",
    component: _232f771d,
    name: "user-reset_password___pt"
  }, {
    path: "/ro/utilizator/afiliat-completeaza-inregistrarea/:code/:code2",
    component: _553e399b,
    name: "user-affiliate_complete_sign_up___ro"
  }, {
    path: "/ro/utilizator/completeaza-inregistrarea/:code/:code2",
    component: _1a51b329,
    name: "user-complete-sign-up___ro"
  }, {
    path: "/ro/utilizator/confirmare-email/:code/:code2",
    component: _3b58408a,
    name: "user-confirm_email___ro"
  }, {
    path: "/ro/utilizator/resetare-parola/:code/:code2",
    component: _232f771d,
    name: "user-reset_password___ro"
  }, {
    path: "/ru/polzovatel/partner-zavershit-registratsiyu/:code/:code2",
    component: _553e399b,
    name: "user-affiliate_complete_sign_up___ru"
  }, {
    path: "/ru/polzovatel/podtverdit-email/:code/:code2",
    component: _3b58408a,
    name: "user-confirm_email___ru"
  }, {
    path: "/ru/polzovatel/reset-password/:code/:code2",
    component: _232f771d,
    name: "user-reset_password___ru"
  }, {
    path: "/ru/polzovatel/zavershit-registratsiyu/:code/:code2",
    component: _1a51b329,
    name: "user-complete-sign-up___ru"
  }, {
    path: "/sq/perdorues/konfirmo-email/:code/:code2",
    component: _3b58408a,
    name: "user-confirm_email___sq"
  }, {
    path: "/sq/perdorues/perfundimi-i-regjistrimit/:code/:code2",
    component: _1a51b329,
    name: "user-complete-sign-up___sq"
  }, {
    path: "/sq/perdorues/rivendos-fjalekalimin/:code/:code2",
    component: _232f771d,
    name: "user-reset_password___sq"
  }, {
    path: "/bg/pretenziya/podpisvane-na-dogovor/:code/:indexes/:step",
    component: _7ad9a7a2,
    name: "claim-sign-contract-code-indexes-step___bg"
  }, {
    path: "/cs/reklamace/podepsani-smlouvy/:code/:indexes/:step",
    component: _7ad9a7a2,
    name: "claim-sign-contract-code-indexes-step___cs"
  }, {
    path: "/de/forderung/vertragsunterschrift/:code/:indexes/:step",
    component: _7ad9a7a2,
    name: "claim-sign-contract-code-indexes-step___de"
  }, {
    path: "/en/claim/sign-contract/:code/:indexes/:step",
    component: _7ad9a7a2,
    name: "claim-sign-contract-code-indexes-step___en"
  }, {
    path: "/es/solicitar/firma-contrato/:code/:indexes/:step",
    component: _7ad9a7a2,
    name: "claim-sign-contract-code-indexes-step___es"
  }, {
    path: "/hu/igenyles/szerzodes-alairas/:code/:indexes/:step",
    component: _7ad9a7a2,
    name: "claim-sign-contract-code-indexes-step___hu"
  }, {
    path: "/it/reclamo/firma-contratto/:code/:indexes/:step",
    component: _7ad9a7a2,
    name: "claim-sign-contract-code-indexes-step___it"
  }, {
    path: "/pl/roszczenie/podpisanie-umowy/:code/:indexes/:step",
    component: _7ad9a7a2,
    name: "claim-sign-contract-code-indexes-step___pl"
  }, {
    path: "/pt/reclamar/assinatura-contrato/:code/:indexes/:step",
    component: _7ad9a7a2,
    name: "claim-sign-contract-code-indexes-step___pt"
  }, {
    path: "/ro/reclamatie/semnatura-contract/:code/:indexes/:step",
    component: _7ad9a7a2,
    name: "claim-sign-contract-code-indexes-step___ro"
  }, {
    path: "/ru/zayavlenie/podpisanie-kontrakta/:code/:indexes/:step",
    component: _7ad9a7a2,
    name: "claim-sign-contract-code-indexes-step___ru"
  }, {
    path: "/sq/ankese/nenshkrimi-kontrate/:code/:indexes/:step",
    component: _7ad9a7a2,
    name: "claim-sign-contract-code-indexes-step___sq"
  }, {
    path: "/bg/blog/:slug",
    component: _5ee7687f,
    name: "blog-slug___bg"
  }, {
    path: "/bg/informatsiya/:page",
    component: _355ca46f,
    name: "info-page___bg"
  }, {
    path: "/bg/izguben-bagazh/:code",
    component: _5429fdec,
    name: "lost-luggage-code___bg"
  }, {
    path: "/bg/nova-pretenziya/:slug",
    component: _0c338d98,
    name: "new-claim-slug___bg"
  }, {
    path: "/bg/nova-zashtita-na-polet/:code",
    component: _4aad1af1,
    name: "new-disruption-protection-code___bg"
  }, {
    path: "/bg/obezshtetenie/:type",
    component: _29563246,
    name: "compensation-type___bg"
  }, {
    path: "/bg/otmenen-polet/:code",
    component: _1298700b,
    name: "cancelled-flight-code___bg"
  }, {
    path: "/bg/otzivi/:code",
    component: _208126b4,
    name: "reviews-code___bg"
  }, {
    path: "/bg/partner-oplakvane/:code",
    component: _7feb1d7c,
    name: "claim-partner-code___bg"
  }, {
    path: "/bg/pomosht/:category",
    component: _88a06b16,
    name: "faq-category___bg"
  }, {
    path: "/bg/preprodaden-polet/:code",
    component: _4639ef52,
    name: "overbooking-flight-code___bg"
  }, {
    path: "/bg/short-link/:code?",
    component: _387dff0a,
    name: "short-link-code___bg"
  }, {
    path: "/bg/status/:internalcode",
    component: _3a7b6a4e,
    name: "status-internalcode___bg"
  }, {
    path: "/cs/blog/:slug",
    component: _5ee7687f,
    name: "blog-slug___cs"
  }, {
    path: "/cs/informace/:page",
    component: _355ca46f,
    name: "info-page___cs"
  }, {
    path: "/cs/kompenzace/:type",
    component: _29563246,
    name: "compensation-type___cs"
  }, {
    path: "/cs/nova-ochrana-letu/:code",
    component: _4aad1af1,
    name: "new-disruption-protection-code___cs"
  }, {
    path: "/cs/novy-narok/:slug",
    component: _0c338d98,
    name: "new-claim-slug___cs"
  }, {
    path: "/cs/overbooking-letu/:code",
    component: _4639ef52,
    name: "overbooking-flight-code___cs"
  }, {
    path: "/cs/partner-stiznost/:code",
    component: _7feb1d7c,
    name: "claim-partner-code___cs"
  }, {
    path: "/cs/pomoc/:category",
    component: _88a06b16,
    name: "faq-category___cs"
  }, {
    path: "/cs/recenze/:code",
    component: _208126b4,
    name: "reviews-code___cs"
  }, {
    path: "/cs/short-link/:code?",
    component: _387dff0a,
    name: "short-link-code___cs"
  }, {
    path: "/cs/stav/:internalcode",
    component: _3a7b6a4e,
    name: "status-internalcode___cs"
  }, {
    path: "/cs/zruseny-let/:code",
    component: _1298700b,
    name: "cancelled-flight-code___cs"
  }, {
    path: "/cs/ztracena-zavazadla/:code",
    component: _5429fdec,
    name: "lost-luggage-code___cs"
  }, {
    path: "/de/bewertungen/:code",
    component: _208126b4,
    name: "reviews-code___de"
  }, {
    path: "/de/blog/:slug",
    component: _5ee7687f,
    name: "blog-slug___de"
  }, {
    path: "/de/entschaedigung/:type",
    component: _29563246,
    name: "compensation-type___de"
  }, {
    path: "/de/hilfe/:category",
    component: _88a06b16,
    name: "faq-category___de"
  }, {
    path: "/de/informationen/:page",
    component: _355ca46f,
    name: "info-page___de"
  }, {
    path: "/de/neue-forderung/:slug",
    component: _0c338d98,
    name: "new-claim-slug___de"
  }, {
    path: "/de/neuer-flugschutz/:code",
    component: _4aad1af1,
    name: "new-disruption-protection-code___de"
  }, {
    path: "/de/partner-beschwerde/:code",
    component: _7feb1d7c,
    name: "claim-partner-code___de"
  }, {
    path: "/de/short-link/:code?",
    component: _387dff0a,
    name: "short-link-code___de"
  }, {
    path: "/de/status/:internalcode",
    component: _3a7b6a4e,
    name: "status-internalcode___de"
  }, {
    path: "/de/stornierter-flug/:code",
    component: _1298700b,
    name: "cancelled-flight-code___de"
  }, {
    path: "/de/uberbuchter-flug/:code",
    component: _4639ef52,
    name: "overbooking-flight-code___de"
  }, {
    path: "/de/verlorenes-gepack/:code",
    component: _5429fdec,
    name: "lost-luggage-code___de"
  }, {
    path: "/en/blog/:slug",
    component: _5ee7687f,
    name: "blog-slug___en"
  }, {
    path: "/en/cancelled-flight/:code",
    component: _1298700b,
    name: "cancelled-flight-code___en"
  }, {
    path: "/en/compensation/:type",
    component: _29563246,
    name: "compensation-type___en"
  }, {
    path: "/en/help/:category",
    component: _88a06b16,
    name: "faq-category___en"
  }, {
    path: "/en/information/:page",
    component: _355ca46f,
    name: "info-page___en"
  }, {
    path: "/en/lost-luggage/:code",
    component: _5429fdec,
    name: "lost-luggage-code___en"
  }, {
    path: "/en/new-claim/:slug",
    component: _0c338d98,
    name: "new-claim-slug___en"
  }, {
    path: "/en/new-disruption-protection/:code",
    component: _4aad1af1,
    name: "new-disruption-protection-code___en"
  }, {
    path: "/en/overbooking-flight/:code",
    component: _4639ef52,
    name: "overbooking-flight-code___en"
  }, {
    path: "/en/partner-claim/:code",
    component: _7feb1d7c,
    name: "claim-partner-code___en"
  }, {
    path: "/en/reviews/:code",
    component: _208126b4,
    name: "reviews-code___en"
  }, {
    path: "/en/short-link/:code?",
    component: _387dff0a,
    name: "short-link-code___en"
  }, {
    path: "/en/status/:internalcode",
    component: _3a7b6a4e,
    name: "status-internalcode___en"
  }, {
    path: "/es/ayuda/:category",
    component: _88a06b16,
    name: "faq-category___es"
  }, {
    path: "/es/blog/:slug",
    component: _5ee7687f,
    name: "blog-slug___es"
  }, {
    path: "/es/equipaje-perdido/:code",
    component: _5429fdec,
    name: "lost-luggage-code___es"
  }, {
    path: "/es/estatus/:internalcode",
    component: _3a7b6a4e,
    name: "status-internalcode___es"
  }, {
    path: "/es/indemnizacion/:type",
    component: _29563246,
    name: "compensation-type___es"
  }, {
    path: "/es/informacion/:page",
    component: _355ca46f,
    name: "info-page___es"
  }, {
    path: "/es/nueva-proteccion-vuelo/:code",
    component: _4aad1af1,
    name: "new-disruption-protection-code___es"
  }, {
    path: "/es/nuevo-reclamo/:slug",
    component: _0c338d98,
    name: "new-claim-slug___es"
  }, {
    path: "/es/reclamo-partner/:code",
    component: _7feb1d7c,
    name: "claim-partner-code___es"
  }, {
    path: "/es/resenas/:code",
    component: _208126b4,
    name: "reviews-code___es"
  }, {
    path: "/es/short-link/:code?",
    component: _387dff0a,
    name: "short-link-code___es"
  }, {
    path: "/es/sobreventa-de-vuelos/:code",
    component: _4639ef52,
    name: "overbooking-flight-code___es"
  }, {
    path: "/es/vuelo-cancelado/:code",
    component: _1298700b,
    name: "cancelled-flight-code___es"
  }, {
    path: "/hu/allapot/:internalcode",
    component: _3a7b6a4e,
    name: "status-internalcode___hu"
  }, {
    path: "/hu/blog/:slug",
    component: _5ee7687f,
    name: "blog-slug___hu"
  }, {
    path: "/hu/elveszett-poggyasz/:code",
    component: _5429fdec,
    name: "lost-luggage-code___hu"
  }, {
    path: "/hu/informacio/:page",
    component: _355ca46f,
    name: "info-page___hu"
  }, {
    path: "/hu/kompenzacio/:type",
    component: _29563246,
    name: "compensation-type___hu"
  }, {
    path: "/hu/partner-panasz/:code",
    component: _7feb1d7c,
    name: "claim-partner-code___hu"
  }, {
    path: "/hu/segitseg/:category",
    component: _88a06b16,
    name: "faq-category___hu"
  }, {
    path: "/hu/short-link/:code?",
    component: _387dff0a,
    name: "short-link-code___hu"
  }, {
    path: "/hu/torolt-jarat/:code",
    component: _1298700b,
    name: "cancelled-flight-code___hu"
  }, {
    path: "/hu/tulfooglalt-jarat/:code",
    component: _4639ef52,
    name: "overbooking-flight-code___hu"
  }, {
    path: "/hu/uj-igenyles/:slug",
    component: _0c338d98,
    name: "new-claim-slug___hu"
  }, {
    path: "/hu/uj-jarat-vedelem/:code",
    component: _4aad1af1,
    name: "new-disruption-protection-code___hu"
  }, {
    path: "/hu/velemenyek/:code",
    component: _208126b4,
    name: "reviews-code___hu"
  }, {
    path: "/it/aiuto/:category",
    component: _88a06b16,
    name: "faq-category___it"
  }, {
    path: "/it/bagaglio-perso/:code",
    component: _5429fdec,
    name: "lost-luggage-code___it"
  }, {
    path: "/it/blog/:slug",
    component: _5ee7687f,
    name: "blog-slug___it"
  }, {
    path: "/it/compensazione/:type",
    component: _29563246,
    name: "compensation-type___it"
  }, {
    path: "/it/informazioni/:page",
    component: _355ca46f,
    name: "info-page___it"
  }, {
    path: "/it/nuova-protezione-volo/:code",
    component: _4aad1af1,
    name: "new-disruption-protection-code___it"
  }, {
    path: "/it/nuovo-reclamo/:slug",
    component: _0c338d98,
    name: "new-claim-slug___it"
  }, {
    path: "/it/recensioni/:code",
    component: _208126b4,
    name: "reviews-code___it"
  }, {
    path: "/it/reclamo-partner/:code",
    component: _7feb1d7c,
    name: "claim-partner-code___it"
  }, {
    path: "/it/short-link/:code?",
    component: _387dff0a,
    name: "short-link-code___it"
  }, {
    path: "/it/stato/:internalcode",
    component: _3a7b6a4e,
    name: "status-internalcode___it"
  }, {
    path: "/it/volo-cancellato/:code",
    component: _1298700b,
    name: "cancelled-flight-code___it"
  }, {
    path: "/it/volo-overbooking/:code",
    component: _4639ef52,
    name: "overbooking-flight-code___it"
  }, {
    path: "/pl/blog/:slug",
    component: _5ee7687f,
    name: "blog-slug___pl"
  }, {
    path: "/pl/informacja/:page",
    component: _355ca46f,
    name: "info-page___pl"
  }, {
    path: "/pl/kompensata/:type",
    component: _29563246,
    name: "compensation-type___pl"
  }, {
    path: "/pl/nowa-ochrona-przed-przerwanie/:code",
    component: _4aad1af1,
    name: "new-disruption-protection-code___pl"
  }, {
    path: "/pl/nowe-roszczenie/:slug",
    component: _0c338d98,
    name: "new-claim-slug___pl"
  }, {
    path: "/pl/odwolany-lot/:code",
    component: _1298700b,
    name: "cancelled-flight-code___pl"
  }, {
    path: "/pl/pomoc/:category",
    component: _88a06b16,
    name: "faq-category___pl"
  }, {
    path: "/pl/przelot-nadbookowany/:code",
    component: _4639ef52,
    name: "overbooking-flight-code___pl"
  }, {
    path: "/pl/recenzje/:code",
    component: _208126b4,
    name: "reviews-code___pl"
  }, {
    path: "/pl/short-link/:code?",
    component: _387dff0a,
    name: "short-link-code___pl"
  }, {
    path: "/pl/skarga-partnera/:code",
    component: _7feb1d7c,
    name: "claim-partner-code___pl"
  }, {
    path: "/pl/status/:internalcode",
    component: _3a7b6a4e,
    name: "status-internalcode___pl"
  }, {
    path: "/pl/zagubiony-bagaz/:code",
    component: _5429fdec,
    name: "lost-luggage-code___pl"
  }, {
    path: "/pt/ajuda/:category",
    component: _88a06b16,
    name: "faq-category___pt"
  }, {
    path: "/pt/avaliacoes/:code",
    component: _208126b4,
    name: "reviews-code___pt"
  }, {
    path: "/pt/bagagem-perdida/:code",
    component: _5429fdec,
    name: "lost-luggage-code___pt"
  }, {
    path: "/pt/blog/:slug",
    component: _5ee7687f,
    name: "blog-slug___pt"
  }, {
    path: "/pt/compensacao/:type",
    component: _29563246,
    name: "compensation-type___pt"
  }, {
    path: "/pt/estado/:internalcode",
    component: _3a7b6a4e,
    name: "status-internalcode___pt"
  }, {
    path: "/pt/informacao/:page",
    component: _355ca46f,
    name: "info-page___pt"
  }, {
    path: "/pt/nova-protecao-voo/:code",
    component: _4aad1af1,
    name: "new-disruption-protection-code___pt"
  }, {
    path: "/pt/novo-reclamacao/:slug",
    component: _0c338d98,
    name: "new-claim-slug___pt"
  }, {
    path: "/pt/reclamacao-parceiro/:code",
    component: _7feb1d7c,
    name: "claim-partner-code___pt"
  }, {
    path: "/pt/short-link/:code?",
    component: _387dff0a,
    name: "short-link-code___pt"
  }, {
    path: "/pt/voo-cancelado/:code",
    component: _1298700b,
    name: "cancelled-flight-code___pt"
  }, {
    path: "/pt/voo-sobrelotado/:code",
    component: _4639ef52,
    name: "overbooking-flight-code___pt"
  }, {
    path: "/ro/ajutor/:category",
    component: _88a06b16,
    name: "faq-category___ro"
  }, {
    path: "/ro/bagaj-pierdut/:code",
    component: _5429fdec,
    name: "lost-luggage-code___ro"
  }, {
    path: "/ro/blog/:slug",
    component: _5ee7687f,
    name: "blog-slug___ro"
  }, {
    path: "/ro/cerere-noua/:slug",
    component: _0c338d98,
    name: "new-claim-slug___ro"
  }, {
    path: "/ro/compensatie/:type",
    component: _29563246,
    name: "compensation-type___ro"
  }, {
    path: "/ro/informatii/:page",
    component: _355ca46f,
    name: "info-page___ro"
  }, {
    path: "/ro/noua-protectie-zbor/:code",
    component: _4aad1af1,
    name: "new-disruption-protection-code___ro"
  }, {
    path: "/ro/recenzii/:code",
    component: _208126b4,
    name: "reviews-code___ro"
  }, {
    path: "/ro/reclamatia-partener/:code",
    component: _7feb1d7c,
    name: "claim-partner-code___ro"
  }, {
    path: "/ro/short-link/:code?",
    component: _387dff0a,
    name: "short-link-code___ro"
  }, {
    path: "/ro/stare/:internalcode",
    component: _3a7b6a4e,
    name: "status-internalcode___ro"
  }, {
    path: "/ro/zbor-anulat/:code",
    component: _1298700b,
    name: "cancelled-flight-code___ro"
  }, {
    path: "/ro/zbor-suprarezervat/:code",
    component: _4639ef52,
    name: "overbooking-flight-code___ro"
  }, {
    path: "/ru/blog/:slug",
    component: _5ee7687f,
    name: "blog-slug___ru"
  }, {
    path: "/ru/informatsiya/:page",
    component: _355ca46f,
    name: "info-page___ru"
  }, {
    path: "/ru/kompensatsia/:type",
    component: _29563246,
    name: "compensation-type___ru"
  }, {
    path: "/ru/novaia-zashchita-poleta/:code",
    component: _4aad1af1,
    name: "new-disruption-protection-code___ru"
  }, {
    path: "/ru/novaya-zayavka/:slug",
    component: _0c338d98,
    name: "new-claim-slug___ru"
  }, {
    path: "/ru/otmenennyi-reis/:code",
    component: _1298700b,
    name: "cancelled-flight-code___ru"
  }, {
    path: "/ru/otzyvy/:code",
    component: _208126b4,
    name: "reviews-code___ru"
  }, {
    path: "/ru/pereprodan-reis/:code",
    component: _4639ef52,
    name: "overbooking-flight-code___ru"
  }, {
    path: "/ru/pomoshch/:category",
    component: _88a06b16,
    name: "faq-category___ru"
  }, {
    path: "/ru/poterianii-bagaj/:code",
    component: _5429fdec,
    name: "lost-luggage-code___ru"
  }, {
    path: "/ru/short-link/:code?",
    component: _387dff0a,
    name: "short-link-code___ru"
  }, {
    path: "/ru/status/:internalcode",
    component: _3a7b6a4e,
    name: "status-internalcode___ru"
  }, {
    path: "/ru/zhaloba-partnera/:code",
    component: _7feb1d7c,
    name: "claim-partner-code___ru"
  }, {
    path: "/sq/ankesa-partner/:code",
    component: _7feb1d7c,
    name: "claim-partner-code___sq"
  }, {
    path: "/sq/bagazhi-i-humbur/:code",
    component: _5429fdec,
    name: "lost-luggage-code___sq"
  }, {
    path: "/sq/blog/:slug",
    component: _5ee7687f,
    name: "blog-slug___sq"
  }, {
    path: "/sq/fluturim-me-tepricerim/:code",
    component: _4639ef52,
    name: "overbooking-flight-code___sq"
  }, {
    path: "/sq/fluturimi-anuluar/:code",
    component: _1298700b,
    name: "cancelled-flight-code___sq"
  }, {
    path: "/sq/gjendja/:internalcode",
    component: _3a7b6a4e,
    name: "status-internalcode___sq"
  }, {
    path: "/sq/informacion/:page",
    component: _355ca46f,
    name: "info-page___sq"
  }, {
    path: "/sq/kerkese-e-re/:slug",
    component: _0c338d98,
    name: "new-claim-slug___sq"
  }, {
    path: "/sq/kompensim/:type",
    component: _29563246,
    name: "compensation-type___sq"
  }, {
    path: "/sq/mbrojtje-e-re-e-fluturimit/:code",
    component: _4aad1af1,
    name: "new-disruption-protection-code___sq"
  }, {
    path: "/sq/ndihme/:category",
    component: _88a06b16,
    name: "faq-category___sq"
  }, {
    path: "/sq/rishikimet/:code",
    component: _208126b4,
    name: "reviews-code___sq"
  }, {
    path: "/sq/short-link/:code?",
    component: _387dff0a,
    name: "short-link-code___sq"
  }, {
    path: "/bg/bankova-smetka/:code/:step",
    component: _138858d7,
    name: "bank-account-code-step___bg"
  }, {
    path: "/bg/dokumenti/:code/:step",
    component: _5935d01f,
    name: "documents-code-step___bg"
  }, {
    path: "/bg/pomosht/:category/:question",
    component: _3994c030,
    name: "faq-category-question___bg"
  }, {
    path: "/bg/pravni-deistviya/:code/:step",
    component: _5bad0df6,
    name: "legal-action-code-step___bg"
  }, {
    path: "/bg/pretenziya/:code/:step",
    component: _2a6728a3,
    name: "claim-code-step___bg"
  }, {
    path: "/bg/vavedete-bankova-smetka/:code/:security",
    component: _36e8d5ea,
    name: "insert-bank-account-code-security___bg"
  }, {
    path: "/cs/bankovni-ucet/:code/:step",
    component: _138858d7,
    name: "bank-account-code-step___cs"
  }, {
    path: "/cs/dokumenty/:code/:step",
    component: _5935d01f,
    name: "documents-code-step___cs"
  }, {
    path: "/cs/pomoc/:category/:question",
    component: _3994c030,
    name: "faq-category-question___cs"
  }, {
    path: "/cs/pravnich-kroky/:code/:step",
    component: _5bad0df6,
    name: "legal-action-code-step___cs"
  }, {
    path: "/cs/reklamace/:code/:step",
    component: _2a6728a3,
    name: "claim-code-step___cs"
  }, {
    path: "/cs/vlozte-bankovni-ucet/:code/:security",
    component: _36e8d5ea,
    name: "insert-bank-account-code-security___cs"
  }, {
    path: "/de/bankkonto-eingeben/:code/:security",
    component: _36e8d5ea,
    name: "insert-bank-account-code-security___de"
  }, {
    path: "/de/bankkonto/:code/:step",
    component: _138858d7,
    name: "bank-account-code-step___de"
  }, {
    path: "/de/dokumente/:code/:step",
    component: _5935d01f,
    name: "documents-code-step___de"
  }, {
    path: "/de/forderung/:code/:step",
    component: _2a6728a3,
    name: "claim-code-step___de"
  }, {
    path: "/de/hilfe/:category/:question",
    component: _3994c030,
    name: "faq-category-question___de"
  }, {
    path: "/de/rechtliche-schritte/:code/:step",
    component: _5bad0df6,
    name: "legal-action-code-step___de"
  }, {
    path: "/en/bank-account/:code/:step",
    component: _138858d7,
    name: "bank-account-code-step___en"
  }, {
    path: "/en/claim/:code/:step",
    component: _2a6728a3,
    name: "claim-code-step___en"
  }, {
    path: "/en/documents/:code/:step",
    component: _5935d01f,
    name: "documents-code-step___en"
  }, {
    path: "/en/help/:category/:question",
    component: _3994c030,
    name: "faq-category-question___en"
  }, {
    path: "/en/insert-bank-account/:code/:security",
    component: _36e8d5ea,
    name: "insert-bank-account-code-security___en"
  }, {
    path: "/en/legal-action/:code/:step",
    component: _5bad0df6,
    name: "legal-action-code-step___en"
  }, {
    path: "/es/accion-legal/:code/:step",
    component: _5bad0df6,
    name: "legal-action-code-step___es"
  }, {
    path: "/es/ayuda/:category/:question",
    component: _3994c030,
    name: "faq-category-question___es"
  }, {
    path: "/es/cuenta-bancaria/:code/:step",
    component: _138858d7,
    name: "bank-account-code-step___es"
  }, {
    path: "/es/documentos/:code/:step",
    component: _5935d01f,
    name: "documents-code-step___es"
  }, {
    path: "/es/introduce-cuenta-bancaria/:code/:security",
    component: _36e8d5ea,
    name: "insert-bank-account-code-security___es"
  }, {
    path: "/es/solicitar/:code/:step",
    component: _2a6728a3,
    name: "claim-code-step___es"
  }, {
    path: "/hu/bankszamla-bemutatasa/:code/:security",
    component: _36e8d5ea,
    name: "insert-bank-account-code-security___hu"
  }, {
    path: "/hu/bankszamla/:code/:step",
    component: _138858d7,
    name: "bank-account-code-step___hu"
  }, {
    path: "/hu/dokumentumok/:code/:step",
    component: _5935d01f,
    name: "documents-code-step___hu"
  }, {
    path: "/hu/igenyles/:code/:step",
    component: _2a6728a3,
    name: "claim-code-step___hu"
  }, {
    path: "/hu/jogi-lepes/:code/:step",
    component: _5bad0df6,
    name: "legal-action-code-step___hu"
  }, {
    path: "/hu/segitseg/:category/:question",
    component: _3994c030,
    name: "faq-category-question___hu"
  }, {
    path: "/it/aiuto/:category/:question",
    component: _3994c030,
    name: "faq-category-question___it"
  }, {
    path: "/it/azione-legale/:code/:step",
    component: _5bad0df6,
    name: "legal-action-code-step___it"
  }, {
    path: "/it/conto-bancario/:code/:step",
    component: _138858d7,
    name: "bank-account-code-step___it"
  }, {
    path: "/it/documenti/:code/:step",
    component: _5935d01f,
    name: "documents-code-step___it"
  }, {
    path: "/it/inserisci-conto-bancario/:code/:security",
    component: _36e8d5ea,
    name: "insert-bank-account-code-security___it"
  }, {
    path: "/it/reclamo/:code/:step",
    component: _2a6728a3,
    name: "claim-code-step___it"
  }, {
    path: "/pl/dokumenty/:code/:step",
    component: _5935d01f,
    name: "documents-code-step___pl"
  }, {
    path: "/pl/dzialanie-prawne/:code/:step",
    component: _5bad0df6,
    name: "legal-action-code-step___pl"
  }, {
    path: "/pl/konto-bankowe/:code/:step",
    component: _138858d7,
    name: "bank-account-code-step___pl"
  }, {
    path: "/pl/podaj-konto-bankowe/:code/:security",
    component: _36e8d5ea,
    name: "insert-bank-account-code-security___pl"
  }, {
    path: "/pl/pomoc/:category/:question",
    component: _3994c030,
    name: "faq-category-question___pl"
  }, {
    path: "/pl/roszczenie/:code/:step",
    component: _2a6728a3,
    name: "claim-code-step___pl"
  }, {
    path: "/pt/acao-legal/:code/:step",
    component: _5bad0df6,
    name: "legal-action-code-step___pt"
  }, {
    path: "/pt/ajuda/:category/:question",
    component: _3994c030,
    name: "faq-category-question___pt"
  }, {
    path: "/pt/conta-bancaria/:code/:step",
    component: _138858d7,
    name: "bank-account-code-step___pt"
  }, {
    path: "/pt/documentos/:code/:step",
    component: _5935d01f,
    name: "documents-code-step___pt"
  }, {
    path: "/pt/introduza-conta-bancaria/:code/:security",
    component: _36e8d5ea,
    name: "insert-bank-account-code-security___pt"
  }, {
    path: "/pt/reclamar/:code/:step",
    component: _2a6728a3,
    name: "claim-code-step___pt"
  }, {
    path: "/ro/actiune-in-instanta/:code/:step",
    component: _5bad0df6,
    name: "legal-action-code-step___ro"
  }, {
    path: "/ro/ajutor/:category/:question",
    component: _3994c030,
    name: "faq-category-question___ro"
  }, {
    path: "/ro/cont-bancar/:code/:step",
    component: _138858d7,
    name: "bank-account-code-step___ro"
  }, {
    path: "/ro/documentele/:code/:step",
    component: _5935d01f,
    name: "documents-code-step___ro"
  }, {
    path: "/ro/introdu-cont-bancar/:code/:security",
    component: _36e8d5ea,
    name: "insert-bank-account-code-security___ro"
  }, {
    path: "/ro/reclamatie/:code/:step",
    component: _2a6728a3,
    name: "claim-code-step___ro"
  }, {
    path: "/ru/bankovskiy-schet/:code/:step",
    component: _138858d7,
    name: "bank-account-code-step___ru"
  }, {
    path: "/ru/dokumenty/:code/:step",
    component: _5935d01f,
    name: "documents-code-step___ru"
  }, {
    path: "/ru/pomoshch/:category/:question",
    component: _3994c030,
    name: "faq-category-question___ru"
  }, {
    path: "/ru/sudebnyy-isk/:code/:step",
    component: _5bad0df6,
    name: "legal-action-code-step___ru"
  }, {
    path: "/ru/vvedite-bankovskiy-schet/:code/:security",
    component: _36e8d5ea,
    name: "insert-bank-account-code-security___ru"
  }, {
    path: "/ru/zayavlenie/:code/:step",
    component: _2a6728a3,
    name: "claim-code-step___ru"
  }, {
    path: "/sq/ankese/:code/:step",
    component: _2a6728a3,
    name: "claim-code-step___sq"
  }, {
    path: "/sq/dokumentet/:code/:step",
    component: _5935d01f,
    name: "documents-code-step___sq"
  }, {
    path: "/sq/llogari-bankare/:code/:step",
    component: _138858d7,
    name: "bank-account-code-step___sq"
  }, {
    path: "/sq/ndihme/:category/:question",
    component: _3994c030,
    name: "faq-category-question___sq"
  }, {
    path: "/sq/shtoni-llogari-bankare/:code/:security",
    component: _36e8d5ea,
    name: "insert-bank-account-code-security___sq"
  }, {
    path: "/sq/veprim-ligjor/:code/:step",
    component: _5bad0df6,
    name: "legal-action-code-step___sq"
  }, {
    path: "/bg/:page",
    component: _8d1a10f8,
    name: "root___bg"
  }, {
    path: "/cs/:page",
    component: _8d1a10f8,
    name: "root___cs"
  }, {
    path: "/de/:page",
    component: _8d1a10f8,
    name: "root___de"
  }, {
    path: "/en/:page",
    component: _8d1a10f8,
    name: "root___en"
  }, {
    path: "/es/:page",
    component: _8d1a10f8,
    name: "root___es"
  }, {
    path: "/hu/:page",
    component: _8d1a10f8,
    name: "root___hu"
  }, {
    path: "/it/:page",
    component: _8d1a10f8,
    name: "root___it"
  }, {
    path: "/pl/:page",
    component: _8d1a10f8,
    name: "root___pl"
  }, {
    path: "/pt/:page",
    component: _8d1a10f8,
    name: "root___pt"
  }, {
    path: "/ro/:page",
    component: _8d1a10f8,
    name: "root___ro"
  }, {
    path: "/ru/:page",
    component: _8d1a10f8,
    name: "root___ru"
  }, {
    path: "/sq/:page",
    component: _8d1a10f8,
    name: "root___sq"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
