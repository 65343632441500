export default remote => ({
    social: (social) => remote.post(`user/login/social/troubleflight/${social}`),
    socialComplete: (social, data) => remote.post(`user/login/social-complete/troubleflight/${social}`, data),
    loggedIn: (session) => remote.post(`user/login/logged-in/${encodeURIComponent(session)}`),
    logout: (session) => remote.post(`user/login/logout/${encodeURIComponent(session)}`),

    login: (email, password) => remote.post(`user/login/log-in/troubleflight`, {email, password}),
    verifyPassword: (session, password) => remote.post(`user/login/verify-password/${encodeURIComponent(session)}`, {password}),
    newPasswordCode: (code, code2, password) => remote.post(`user/login/new-password-code/troubleflight`, {code, code2, password}),

    edit: (session, data) => remote.post(`user/login/edit/${encodeURIComponent(session)}`, data),
    editPassword: (session, data) => remote.post(`user/login/edit-password/${encodeURIComponent(session)}`, data),

    registration: (data) => remote.post(`user/login/create/troubleflight`, data),

    affiliateRequest: (session, data) => remote.post(`user/affiliate/request/${encodeURIComponent(session)}`, data),
    affiliateStatistics: (session, data) => remote.post(`user/affiliate/statistics/${encodeURIComponent(session)}`, data),
    affiliateClaims: (session, data) => remote.post(`user/affiliate/claims/${encodeURIComponent(session)}`, data),
    affiliatePayments: (session, data) => remote.post(`user/affiliate/payments/${encodeURIComponent(session)}`, data),
    affiliatePaymentRequest: (session, data) => remote.post(`user/affiliate/payment-request/${encodeURIComponent(session)}`, data),
    affiliatePaymentInformation: (session, data) => remote.post(`user/affiliate/payment-information/${encodeURIComponent(session)}`, data),
    affiliateCreativity: () => remote.get(`affiliate/creativity/troubleflight`),
    affiliateConvertToAffiliate: (session, data) => remote.post(`user/affiliate/convert-to-affiliate/${encodeURIComponent(session)}`, data),
    affiliateJoinAffiliate: (session, data) => remote.post(`user/affiliate/join-affiliate/${encodeURIComponent(session)}`, data),
    affiliateJoinReferAFriend: (session, data) => remote.post(`user/affiliate/join-refer-friend/${encodeURIComponent(session)}`, data),
    affiliatePaymentDetails: (session, data) => remote.post(`user/affiliate/payment-details/${encodeURIComponent(session)}`, data),
    affiliatePaymentDetailsMethod: (session) => remote.get(`user/affiliate/payment-method/${encodeURIComponent(session)}`),
    affiliateSavePaymentDetailsMethod: (session, data) => remote.post(`user/affiliate/payment-method/${encodeURIComponent(session)}`, data),

    resetPassword: (email) => remote.post(`user/login/reset-password/troubleflight`, {email: email}),
    resetPasswordInformation: (code, code2) => remote.post(`user/login/reset-password-information/troubleflight`, {code, code2}),
    changeEmailConfirm: (code, code2) => remote.post(`user/login/change-email-confirm/troubleflight`, {code, code2}),

    preCompleted: (code, code2) => remote.get(`user/pre-completed/${code}/${code2}`),
    completePreCompleted: (code, code2, data) => remote.post(`user/pre-completed/${code}/${code2}`, data),
    verifyUser: (email) => remote.post(`user/login/email/troubleflight`, {email: email}),
    uploadAvatar: (session, file) => remote.post(`user/login/upload-avatar/${session}`,
        file, {headers: {'Content-Type': 'multipart/form-data'}}),
});
