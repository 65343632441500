import Vue from 'vue'

let fn = {};

fn.all = async (language) => {
    if (!process['cachePages' + language]) {
        let tmp = await fetch(
            process.env.apiEndpoint + 'content/all/trouble-flight/' + language.substr(0, 2) + '/' + language.substr(3, 2)
        ).then((res) => {
            const data = res.json();
            return data;
        });
        process['cachePages' + language] = tmp.response;
    }
    return process['cachePages' + language];
};

fn.get = async (code, language) => {
    return (await fn.all(language)).find(page => page.code === code);
};

fn.getSlug = async (code, language) => {
    var foundPage = false;
    (await fn.all(language)).forEach((page) => {
        if (typeof page.i18n !== "undefined") {
            Object.keys(page.i18n.slugs).forEach((k) => {
                if (page.i18n.slugs[k] === code) {
                    foundPage = page;
                }
            });
        }
    });
    return foundPage;
};

fn.slug = async (slug, language) => {
    return (await fn.all(language)).find(page => page.slug === slug);
};

Vue.prototype.$cachePages = fn;