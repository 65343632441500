import Vue from 'vue'

Vue.prototype.$cacheTypes = async (language) => {
    if (!process['cacheTypes'+language]) {
        let tmp = await fetch(
            process.env.apiEndpoint + 'claims/type/all/' + language
        ).then((res) => {
            const data = res.json();
            return data;
        });
        process['cacheTypes'+language] = tmp.response;
    }
    return process['cacheTypes'+language];
}