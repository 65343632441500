import Vue               from 'vue';

let currencies = {
    'USD': {
        name              : 'USD',
        symbol            : '$',
        thousandsSeparator: ',',
        fractionCount     : 2,
        fractionSeparator : '.',
        symbolPosition    : 'front',
        symbolSpacing     : false,
        avoidEmptyDecimals: '##',
        short             : {
            symbol            : '$',
            thousandsSeparator: ',',
            fractionCount     : 0,
            fractionSeparator : '',
            symbolPosition    : 'front',
            symbolSpacing     : false,
            avoidEmptyDecimals: '##'
        }
    },
    'GBP': {
        name              : 'GBP',
        symbol            : '£',
        thousandsSeparator: ',',
        fractionCount     : 2,
        fractionSeparator : '.',
        symbolPosition    : 'front',
        symbolSpacing     : false,
        avoidEmptyDecimals: '##',
        short             : {
            symbol            : '£',
            thousandsSeparator: ',',
            fractionCount     : 0,
            fractionSeparator : '',
            symbolPosition    : 'front',
            symbolSpacing     : false,
            avoidEmptyDecimals: '##'
        }
    },
    'EUR': {
        name              : 'EUR',
        symbol            : '€',
        thousandsSeparator: '.',
        fractionCount     : 2,
        fractionSeparator : ',',
        symbolPosition    : 'front',
        symbolSpacing     : false,
        avoidEmptyDecimals: '',
        short             : {
            symbol            : '€',
            thousandsSeparator: '.',
            fractionCount     : 0,
            fractionSeparator : '',
            symbolPosition    : 'front',
            symbolSpacing     : false,
            avoidEmptyDecimals: ''
        }
    },
    'RON': {
        name              : 'RON',
        symbol            : 'Lei',
        thousandsSeparator: ' ',
        fractionCount     : 0,
        fractionSeparator : '',
        symbolPosition    : 'front',
        symbolSpacing     : true,
        avoidEmptyDecimals: '',
        short             : {
            name              : 'RON',
            symbol            : 'Lei ',
            thousandsSeparator: '',
            fractionCount     : 0,
            fractionSeparator : '',
            symbolPosition    : 'front',
            symbolSpacing     : true,
            avoidEmptyDecimals: ''
        }
    },
    'RUB': {
        name              : 'RUB',
        symbol            : '₽',
        thousandsSeparator: ' ',
        fractionCount     : 0,
        fractionSeparator : '',
        symbolPosition    : 'front',
        symbolSpacing     : true,
        avoidEmptyDecimals: '',
        short             : {
            name              : 'RUB',
            symbol            : '₽',
            thousandsSeparator: '',
            fractionCount     : 0,
            fractionSeparator : '',
            symbolPosition    : 'front',
            symbolSpacing     : true,
            avoidEmptyDecimals: ''
        }
    },
    'MDL': {
        name              : 'MDL',
        symbol            : 'MDL',
        thousandsSeparator: '',
        fractionCount     : 0,
        fractionSeparator : '',
        symbolPosition    : 'after',
        symbolSpacing     : true,
        avoidEmptyDecimals: '',
        short             : {
            name              : '',
            symbol            : 'MDL',
            thousandsSeparator: '',
            fractionCount     : 0,
            fractionSeparator : '',
            symbolPosition    : 'after',
            symbolSpacing     : true,
            avoidEmptyDecimals: ''
        }
    }
};

let numberFormat = function (number, decimals, dec_point, thousands_point) {
    if (number == null || !isFinite(number)) {
        throw new TypeError('number is not valid');
    }

    if (typeof decimals === 'undefined') {
        var len  = number.toString()
            .split('.').length;
        decimals = len > 1 ? len : 0;
    }

    if (typeof dec_point === 'undefined') {
        dec_point = '.';
    }

    if (typeof thousands_point === 'undefined') {
        thousands_point = ',';
    }

    number = parseFloat(number)
        .toFixed(decimals);

    number = number.replace('.', dec_point);

    var splitNum = number.split(dec_point);
    splitNum[0]  = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
    number       = splitNum.join(dec_point);

    return number;
};

Vue.prototype.$currency = (amount, currency, short) => {
    let settings;
    if (short) {
        settings = currencies[currency].short;
    } else {
        settings = currencies[currency];
    }

    var formatted = '';
    if (settings.symbolPosition === 'front') {
        formatted += settings.symbol;
        if (settings.symbolSpacing) {
            formatted += ' ';
        }
    }

    formatted += numberFormat(amount, settings.fractionCount, settings.fractionSeparator,
        settings.thousandsSeparator);

    if (settings.symbolPosition === 'after') {
        if (settings.symbolSpacing) {
            formatted += ' ';
        }
        formatted += settings.symbol;
    }
    return formatted;
};
