import Vue     from 'vue';

Vue.prototype.$alternativePages = async function(key, pages) {
    let final_pages = {};
    this.$i18n.locales.forEach((locale) => {
        final_pages[locale.code] = {};
        final_pages[locale.code][key] = pages[locale.code.substr(0, 2)];
    });

    await this.$store.dispatch('i18n/setRouteParams', final_pages);
};
