export default remote => ({
    aircompanies: (session) => remote.get(`travel-agency/helper/aircompanies/${session}`),
    airports: (session, type) => remote.get(`travel-agency/helper/airports/${session}/${type}`),
    newClaim: (session) => remote.put(`travel-agency/claim/new/${session}`),
    claim: (session, code) => remote.get(`travel-agency/claim/${session}/${code}`),
    claimUpdate: (session, code, claim) => remote.post(`travel-agency/claim/${session}/${code}`, claim),
    upload: (session, code, file) => remote.post(`travel-agency/claim/upload/${session}/${code}`,
        file, {headers: {'Content-Type': 'multipart/form-data'}}),


    uploadGeneric: (session, file) => remote.post(`travel-agency/upload/generic/${session}`,
        file, {headers: {'Content-Type': 'multipart/form-data'}}),
    deleteGenericFile: (session, file) => remote.delete(`travel-agency/upload/generic/${session}`, {params: {file: file}}),

    uploadDocument: (session, code, passenger, type, file) => remote.post(`travel-agency/claim/upload-document/${session}/${code}/${passenger}/${type}`,
        file, {headers: {'Content-Type': 'multipart/form-data'}}),

    devices: (session) => remote.get(`travel-agency/device/${session}`),
    deviceTask: (session, data) => remote.post(`travel-agency/device/task/${session}`, data),

    deleteFile: (session, code, file) => remote.post(`travel-agency/claim/delete-file/${session}/${code}`, {file}),
    refreshDeviceTask: (session, task_id) => remote.post(`travel-agency/device/keep-task-alive/${session}/${task_id}`),
    signClaim: (session, claim_id, signature, index) => remote.post(`travel-agency/claim/sign/${session}/${claim_id}`, {
        signature,
        index
    }),
    inviteSignature: (session, claim_id, methods, indexes, details) => remote.post(`travel-agency/claim/invite-sign/${session}/${claim_id}`, Object.assign({
        methods,
        indexes
    }, details)),
    claims: (session, data) => remote.get(`travel-agency/claim/claims/${session}`, {params: data}),
    statisticClaims: (session) => remote.get(`travel-agency/claim/statistic-claims/${session}`),

    newClaimOffline: (session, files) => remote.put(`travel-agency/claim/offline/${session}`, {attached_files: files}),
    postComplete: (session, code) => remote.post(`travel-agency/claim/complete/${session}/${code}`),
    getOfflineForm: (session, language) => remote.get(`travel-agency/document/offline-form/${session}`, {params: {contract_language: language}}),

    deleteClaim: (session, internal_code, code) => remote.delete(`travel-agency/claim/${session}/${internal_code}/${code}`)
})