import { render, staticRenderFns } from "./HeaderFull.vue?vue&type=template&id=55e1eea6"
import script from "./HeaderFull.vue?vue&type=script&lang=js"
export * from "./HeaderFull.vue?vue&type=script&lang=js"
import style0 from "./HeaderFull.vue?vue&type=style&index=0&id=55e1eea6&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Dropdown: require('/code/current/components/Dropdown.vue').default})
