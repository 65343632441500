
export default {

  props: {

    open: {
      type: Boolean,
      default: false,
    },
    onConfirm: {},
    noCancel: {
      type: Boolean,
      default: false
    },
    noSubmit: {
      type: Boolean,
      default: false
    },
    overflowContent: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: ''
    },
    buttonConfirm: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formLocal: {}
    }
  },
  computed: {
    tButtonConfirm() {
      if (this.buttonConfirm) {
        return this.buttonConfirm;
      } else {
        return this.$t('claim.Confirm');
      }
    },
  },
  created() {
    this.formLocal = this.form;
  },
  methods: {
    cancel() {
      this.$emit('update:open', false);
    },
    async confirm() {
      console.log('here');
      const test = await this.onConfirm();
      console.log('here', test);
      if (test) {
        this.$emit('update:open', false);
      }
    }
  },
  watch: {
    'formLocal': function () {
      this.$emit('update:form', this.formLocal);
    }
  }
}
