
export default {
  data() {
    return {
      pages: require('../content/' + this.$i18n.localeProperties.code.substr(0, 2) + '/contents.json'),
      types: require('../content/' + this.$i18n.localeProperties.code.substr(0, 2) + '/claim_types.json')
    };
  },
  props: ['hideLink'],
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale);
    }
  }
};
