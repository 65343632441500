
    import HeaderFull from '../components/HeaderFull.vue'
    import FooterFull from '../components/FooterFull.vue'

    export default {
        name: 'NuxtError',
        components: {
            HeaderFull,
            FooterFull
        },
        props: {
            error: {
                type: Object,
                default: null
            }
        },
        computed: {
            statusCode () {
                return (this.error && this.error.statusCode) || 500
            },
            message () {
                return this.error.message
            }
        },
        head () {
            return {
                title: this.message,
                meta: [
                    {
                        name: 'viewport',
                        content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0'
                    }
                ]
            }
        }
    }
