import Vue from 'vue';

Vue.directive('drag-drop', {
    bind(el, binding, vnode) {
        const options = {
            uploadHandler: binding.value.uploadHandler,
            activeClass: binding.value.activeClass || 'drag-drop-active',
            message: binding.value.message || 'Release the file here',
        };

        let enteredChildren = 0;

        const dropHandler = (event) => {
            event.preventDefault();
            event.stopPropagation();

            enteredChildren = 0;
            el.classList.remove(options.activeClass);

            const files = event.dataTransfer.files;
            if (files.length > 0) {
                Array.from(files).forEach((file) => {
                    options.uploadHandler(file);
                });
            }
        };

        const dragOverHandler = (event) => {
            event.preventDefault();
            event.stopPropagation();
            event.dataTransfer.dropEffect = 'copy';
        };

        const dragEnterHandler = (event) => {
            event.preventDefault();
            event.stopPropagation();
            enteredChildren++;
            el.classList.add(options.activeClass);
        };

        const dragLeaveHandler = (event) => {
            event.preventDefault();
            event.stopPropagation();
            enteredChildren--;
            if (enteredChildren === 0) {
                el.classList.remove(options.activeClass);
            }
        };

        el.addEventListener('drop', dropHandler);
        el.addEventListener('dragover', dragOverHandler);
        el.addEventListener('dragenter', dragEnterHandler);
        el.addEventListener('dragleave', dragLeaveHandler);

        el.cleanup = () => {
            el.removeEventListener('drop', dropHandler);
            el.removeEventListener('dragover', dragOverHandler);
            el.removeEventListener('dragenter', dragEnterHandler);
            el.removeEventListener('dragleave', dragLeaveHandler);
        };
    },
    unbind(el) {
        el.cleanup();
    },
});