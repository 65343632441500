import Vue from 'vue';
import Dialog from '~/components/Dialog.vue';

const ConfirmDialog = Vue.extend({
    components: {
        Dialog
    },
    props: {
        message: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            open: true
        }
    },
    methods: {
        confirm(result) {
            this.$emit('close', result);
        }
    },
    template: `
      <Dialog :open.sync="open">
          <template v-slot:header>
            Are you sure?
          </template>
          <template v-slot:content>
          </template>
      </Dialog>
    `
});

Vue.prototype.$confirm = function (message) {
    return new Promise((resolve) => {
        const dialog = new ConfirmDialog({
            el: document.createElement('div'),
            propsData: {
                message
            },
            methods: {
                close(result) {
                    resolve(result);
                    dialog.$destroy();
                }
            }
        });
        dialog.$on('close', dialog.close);
        dialog.$mount();
        document.body.appendChild(dialog.$el);
    });
};