import { joinURL } from 'ufo'

export function getImage(src, { modifiers = {}, baseURL = "https://cdn.troubleflight.com/v2" } = {}, { options, nuxtContext, $img }) {
    const { width, height, format, fit } = modifiers;
    const allowedFormats = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'ico', 'pdf'];
    const operations = [];

    const url = new URL(src, baseURL);

    // Apply transformations


    // Check if the format is allowed and then apply
    if (format && allowedFormats.includes(format.toLowerCase())) {
        operations.push(`format=${format}`);
        if (width) operations.push(`w=${width}`);
        if (height) operations.push(`h=${height}`);
    }

    const operationsString = operations.join('&');
    return {
        url: baseURL + src + '?' + operationsString
    }
}