
import Dropdown from '../components/Dropdown.vue';

export default {
  components: {
    Dropdown
  },
  data() {
    return {
      types: require('../content/' + this.$i18n.localeProperties.code.substr(0, 2) + '/claim_types.json'),
      pages: require('../content/' + this.$i18n.localeProperties.code.substr(0, 2) + '/contents.json')
    };
  },
  props: {
    image: Number,
    hideLinks: {
      default: false,
      required: false,
      type: Boolean
    }
  },
  methods: {
    page(code) {
      console.log('calling');
      this.pages.forEach((page) => {
        console.log(page);
        if (page.code === code) {
          return Object.assign({}, page);
        }
      });
      return false;
    }
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale);
    }
  }
}
