export const state = () => ({
    title: '',
    description: '',
    keywords: '',
    image: '',
    canonical: '',
    index: 'index'
});

export const mutations = {
    setTitle(state, title) {
        state.title = title;
    },
    setDescription(state, description) {
        state.description = description;
    },
    setKeywords(state, keywords) {
        state.keywords = keywords;
    },
    setImage(state, image) {
        state.image = image;
    },
    setCanonical(state, canonical) {
        state.canonical = canonical;
    },
    setIndex(state, index) {
        state.index = index;
    }
};
