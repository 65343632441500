import { extend, localize }                                               from 'vee-validate';
import { required, email, min, max, integer, alpha_num, required_if, is, confirmed } from 'vee-validate/dist/rules';
import Vue                                                                from 'vue';
import { ValidationProvider, ValidationObserver }                         from 'vee-validate';

// Install required rule.
extend('required', required);
extend('email', email);
extend('min', min);
extend('max', max);
extend('integer', integer);
extend('alpha_num', alpha_num);
extend('required_if', required_if);
extend('is', is);
extend('confirmed', confirmed);

extend('verify_password', {
    getMessage: field => `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
    validate: value => {
        var strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+=\-\[\]{}\|;:'",<.>\/`~])(?=.{8,})/gm;
        return strongRegex.test(value);
    }
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
